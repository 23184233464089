import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import blogdata from "./blogdata.json";
import workData from "./workdata.json";


export function Home(props){
    const[loadMorePortfolioText, setLoadMorePortfolioText] = useState("View More Works")
    const[btnPortfolioIcon, setBtnPortfolioIcon] = useState("/images/arrow_drop_down.svg")
    const[portCardDisplay, setPortCardDisplay] = useState({display:"none"})

    const[loadMoreAwardText, setLoadMoreAwardText] = useState("View More Awards")
    const[btnAwardIcon, setBtnAwardIcon] = useState("/images/arrow_drop_down.svg")
    const[awardCardDisplay, setAwadCardDisplay] = useState({display:"none"})

    // const[loadMoreBlogText, setLoadMoreBlogText] = useState("View More Blogs")
    // const[btnBlogIcon, setBtnBlogIcon] = useState("./images/arrow_drop_down.svg")

    const[blogdetails, setBlogDetails] = useState([]);

    function getblogData(){
        setBlogDetails([...blogdetails, blogdata[0], blogdata[1], blogdata[2]])
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        getblogData();
    }, []);

    function handlePortfolioMore(){
        if(loadMorePortfolioText==="View More Works"){
            setLoadMorePortfolioText("View Less")
            setBtnPortfolioIcon("/images/arrow_drop_up.svg");
            setPortCardDisplay({display:"flex"})
        } else{
            setLoadMorePortfolioText("View More Works")
            setBtnPortfolioIcon("/images/arrow_drop_down.svg");
            setPortCardDisplay({display:"none"})
        }
    }

    function handleMoreAward(){
        if(loadMoreAwardText==="View More Awards"){
            setLoadMoreAwardText("View less")
            setBtnAwardIcon("/images/arrow_drop_up.svg")
            setAwadCardDisplay({display:"flex"})
        }
        else{
            setLoadMoreAwardText("View More Awards")
            setBtnAwardIcon("/images/arrow_drop_down.svg")
            setAwadCardDisplay({display:"none"})
        }
    }

    // function handleBlogCardMore(){
    //     if(loadMoreBlogText==="View More Blogs"){
    //         setLoadMoreBlogText("View Less")
    //         setBtnBlogIcon("./images/arrow_drop_up.svg")
    //         setBlogDetails(blogdata)
    //     } else{
    //         setLoadMoreBlogText("View More Blogs")
    //         setBtnBlogIcon("./images/arrow_drop_down.svg")
    //         blogdetails.splice(3, 3)
    //     }
    // } -------- this function is usable in future --------------

    function handleCardBlur1(){
        document.getElementById("blur-card-1").style.filter="blur(0px)";
        document.getElementById("blur-card-2").style.filter="blur(4px)"
        document.getElementById("blur-card-3").style.filter="blur(4px)"
        document.getElementById("blur-card-4").style.filter="blur(4px)"
        document.getElementById("blur-card-5").style.filter="blur(4px)"
        document.getElementById("blur-card-6").style.filter="blur(4px)"

        document.getElementById("blur-card-2").style.opacity="50%"
        document.getElementById("blur-card-3").style.opacity="50%"
        document.getElementById("blur-card-4").style.opacity="50%"
        document.getElementById("blur-card-5").style.opacity="50%"
        document.getElementById("blur-card-6").style.opacity="50%"
    }
    function handleCardBlur2(){
        document.getElementById("blur-card-1").style.filter="blur(3px)";
        document.getElementById("blur-card-2").style.filter="blur(0px)"
        document.getElementById("blur-card-3").style.filter="blur(3px)"
        document.getElementById("blur-card-4").style.filter="blur(3px)"
        document.getElementById("blur-card-5").style.filter="blur(3px)"
        document.getElementById("blur-card-6").style.filter="blur(3px)"

        document.getElementById("blur-card-1").style.opacity="50%"
        document.getElementById("blur-card-3").style.opacity="50%"
        document.getElementById("blur-card-4").style.opacity="50%"
        document.getElementById("blur-card-5").style.opacity="50%"
        document.getElementById("blur-card-6").style.opacity="50%"
    }
    function handleCardBlur3(){
        document.getElementById("blur-card-1").style.filter="blur(3px)";
        document.getElementById("blur-card-2").style.filter="blur(3px)"
        document.getElementById("blur-card-3").style.filter="blur(0px)"
        document.getElementById("blur-card-4").style.filter="blur(3px)"
        document.getElementById("blur-card-5").style.filter="blur(3px)"
        document.getElementById("blur-card-6").style.filter="blur(3px)"

        document.getElementById("blur-card-2").style.opacity="50%"
        document.getElementById("blur-card-1").style.opacity="50%"
        document.getElementById("blur-card-4").style.opacity="50%"
        document.getElementById("blur-card-5").style.opacity="50%"
        document.getElementById("blur-card-6").style.opacity="50%"
    }
    function handleCardBlur4(){
        document.getElementById("blur-card-1").style.filter="blur(3px)";
        document.getElementById("blur-card-2").style.filter="blur(3px)"
        document.getElementById("blur-card-3").style.filter="blur(3px)"
        document.getElementById("blur-card-4").style.filter="blur(0px)"
        document.getElementById("blur-card-5").style.filter="blur(3px)"
        document.getElementById("blur-card-6").style.filter="blur(3px)"

        document.getElementById("blur-card-2").style.opacity="50%"
        document.getElementById("blur-card-3").style.opacity="50%"
        document.getElementById("blur-card-1").style.opacity="50%"
        document.getElementById("blur-card-5").style.opacity="50%"
        document.getElementById("blur-card-6").style.opacity="50%"
    }
    function handleCardBlur5(){
        document.getElementById("blur-card-1").style.filter="blur(3px)";
        document.getElementById("blur-card-2").style.filter="blur(3px)"
        document.getElementById("blur-card-3").style.filter="blur(3px)"
        document.getElementById("blur-card-4").style.filter="blur(3px)"
        document.getElementById("blur-card-5").style.filter="blur(0px)"
        document.getElementById("blur-card-6").style.filter="blur(3px)"

        document.getElementById("blur-card-2").style.opacity="50%"
        document.getElementById("blur-card-3").style.opacity="50%"
        document.getElementById("blur-card-4").style.opacity="50%"
        document.getElementById("blur-card-1").style.opacity="50%"
        document.getElementById("blur-card-6").style.opacity="50%"
    }
    function handleCardBlur6(){
        document.getElementById("blur-card-1").style.filter="blur(3px)";
        document.getElementById("blur-card-2").style.filter="blur(3px)"
        document.getElementById("blur-card-3").style.filter="blur(3px)"
        document.getElementById("blur-card-4").style.filter="blur(3px)"
        document.getElementById("blur-card-5").style.filter="blur(3px)"
        document.getElementById("blur-card-6").style.filter="blur(0px)"

        document.getElementById("blur-card-2").style.opacity="50%"
        document.getElementById("blur-card-3").style.opacity="50%"
        document.getElementById("blur-card-4").style.opacity="50%"
        document.getElementById("blur-card-5").style.opacity="50%"
        document.getElementById("blur-card-1").style.opacity="50%"
    }
    function handleCardBlurClear(){
        document.getElementById("blur-card-1").style.filter="blur(0px)";
        document.getElementById("blur-card-2").style.filter="blur(0px)"
        document.getElementById("blur-card-3").style.filter="blur(0px)"
        document.getElementById("blur-card-4").style.filter="blur(0px)"
        document.getElementById("blur-card-5").style.filter="blur(0px)"
        document.getElementById("blur-card-6").style.filter="blur(0px)"

        document.getElementById("blur-card-2").style.opacity="100%"
        document.getElementById("blur-card-3").style.opacity="100%"
        document.getElementById("blur-card-4").style.opacity="100%"
        document.getElementById("blur-card-5").style.opacity="100%"
        document.getElementById("blur-card-6").style.opacity="100%"
        document.getElementById("blur-card-1").style.opacity="100%"
    }

    return(
        <div>
            <div className="hero-section">
                <p className="hero-section-blue-para">
                <img src="/images/para_start_leaf.svg" alt="" /> &nbsp;&nbsp;&nbsp;Web & UI <img src="/images/blueDott.svg" alt="" /> UX Design agency for &nbsp; <div className="saas-tag">SaaS</div> <div className="agencies-tag">Agencies</div> &nbsp;&nbsp;&nbsp;<img src="./images/para_end_leaf.svg" alt="" />
                </p>

                <div className="hero-section-heading">
                    <h1>Experienced<br></br> Product Designers<br></br> for Committed Brands</h1>
                </div>
                <div className="hero-section-heading-mobile">
                    <h1>Experienced Product Designers for Committed<br /> Brands</h1>
                </div>

                <div className="hero-section-grey-para">
                    <p>Our skilled product designers make great solutions easy. We help committed brands bring their ideas to life smoothly. Choose us for a simple and effective design experience that boosts your brand.</p>
                </div>

                <div className="hero-section-buttons">
                    <button onClick={props.openContactForm}>Contact Us
                        <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(255, 255, 255, 0.11)", borderRadius:"inherit"}}></div> 
                    </button>
                    <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="./images/arrow_right_alt.svg" alt="" />
                        <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                    </button>
                </div>

                <p className="calendly-para">
                    Scheduling a call is managed by <img src="./images/calendly_logo.svg" alt="" />
                </p>
            </div>
            {/* hero section ended ---------*/}

            <div className="client-logo-section">
                <p>
                10,000+ design project delivered to 50+happy users
                </p>
                <div className="client-logo-static">
                    <img src="/images/airbnb_logo.svg" alt="" />
                    <img src="/images/figma_logo.svg" alt="" />
                    <img src="/images/airbnb_logo.svg" alt="" />
                    <img src="/images/figma_logo.svg" alt="" />
                    <img src="/images/slack_logo.svg" alt="" />
                    <img src="/images/strip_logo.svg" alt="" />
                    <img src="/images/slack_logo.svg" alt="" />
                    <img src="/images/strip_logo.svg" alt="" />
                </div>

                <marquee className="client-logo-marquee">
                    <img src="/images/airbnb_logo.svg" alt="" />
                    <img src="/images/figma_logo.svg" alt="" />
                    <img src="/images/airbnb_logo.svg" alt="" />
                    <img src="/images/figma_logo.svg" alt="" />
                    <img src="/images/slack_logo.svg" alt="" />
                    <img src="/images/strip_logo.svg" alt="" />
                    <img src="/images/slack_logo.svg" alt="" />
                    <img src="/images/strip_logo.svg" alt="" />
                </marquee>
            </div>
            {/* client logo section ended -------*/}
            <div id="aboutUs"></div>

            {/* about us section started------ */}
            <div className="about-us-section">
                <div className="team-card">
                    <div>
                        <div>
                            <p className="about-us-para-blue">ABOUT US</p>
                            <h3 className="about-us-heading">We are small <img style={{width:"46px", height:"46px"}} src="/images/pinching_hand.png" alt="" /> yet unique team</h3>
                        </div>

                        <div id="chat-image">
                            <img src="/images/chat.png" alt="" />
                        </div>
                        
                        <p className="about-us-para-grey">
                            Welcome! We’re a small, passionate team with deep SaaS expertise. Our diverse skills help us craft innovative, tailored solutions just for you. We're dedicated to your success. Discover what makes us unique.
                        </p>
                    </div>

                    <div>
                        <img className="team-card-image" src="/images/team_emoji.png" alt="" />
                        <img className="team-card-image-mobile" src="/images/team_emoji_mobile.png" alt="" />
                    </div>
                </div>

                <div className="moto-card">
                    <div>
                        <img src="/images/moto_img.png" alt="" />
                    </div>
                    <div>
                        <p className="moto-para-blue">OUR MOTO</p>
                        <h3 className="moto-heading">Not stopping Making better</h3>
                    </div>
                </div>
            </div>
            {/* About us section ended----------*/}
            <div id="services">

            </div>
            {/* Services section-1 started -------*/}
            <div className="services-section-1">
                <div className="services-section-1-card">
                    <div>
                        <p className="sevices-para-blue">SERVICES</p>
                        <h3 className="services-heading">Expertise made out of quality</h3>
                        <p className="services-para-grey">Explore our services: SaaS solutions, UX research, UI concepts, product discovery, UX audits, website design, and mobile design. We create user-friendly experiences to boost engagement and growth. Let us transform your business with our innovative solutions.</p>
                    </div>
                    <div>
                        <img className="mobiles-image" src="/images/services_mobile.png" alt="" />
                    </div>
                </div>
            </div>
            {/* services section-1 ended------ */}

            {/* services section-2 started---- */}
            <div className="services-section-2">
                <div className="service-card">
                    <p className="service-para-green">SERVICES</p>
                    <div className="card-logo">
                        <img src="/images/strategy_logo.svg" alt="" />
                    </div>
                    <h4 className="service-heading">Strategy</h4>
                    <p className="service-para-grey">We craft strategies that deliver results, blending creativity with insight to bring your vision to life and ensure lasting growth.</p>

                    <ul className="service-list">
                        <li>
                            <img src="/images/binoculars.svg" alt="" /> product Discovery
                        </li>
                        <li>
                            <img src="/images/book.svg" alt="" /> Proof Concept
                        </li>
                        <li>
                            <img src="/images/pencile.svg" alt="" /> UX Audit
                        </li>
                        <li>
                            <img src="/images/device_logo.svg" alt="" /> UI Concept
                        </li>
                    </ul>
                </div>

                <div className="service-card">
                    <p className="service-para-pink">SERVICES</p>
                    <div className="card-logo">
                        <img src="/images/design_logo.svg" alt="" />
                    </div>
                    <h4 className="service-heading">Design</h4>
                    <p className="service-para-grey">PoC is an important step in products creation. As a proof of concept agency, we can help you to verify the feasibility, potential and viability of your idea.</p>

                    <ul className="service-list">
                        <li>
                            <img src="/images/click_logo.svg" alt="" /> UI/UX Design
                        </li>
                        <li>
                            <img src="/images/laptop_logo.svg" alt="" /> Website Design
                        </li>
                        <li>
                            <img src="/images/keyboard_logo.svg" alt="" /> Saas Design
                        </li>
                        <li>
                            <img src="/images/mobile_logo.svg" alt="" /> Mobile Design
                        </li>
                    </ul>
                </div>

                <div className="service-card">
                    <p className="service-para-yellow">SERVICES</p>
                    <div className="card-logo">
                        <img src="/images/development_logo.svg" alt="" />
                    </div>
                    <h4 className="service-heading">Development</h4>
                    <p className="service-para-grey">We turn ideas into powerful digital solutions for seamless experiences and top performance. Let's make your vision real.</p>

                    <ul className="service-list">
                        <li>
                            <img src="/images/snif_logo.svg" alt="" /> No-Code Development
                        </li>
                        <li>
                            <img src="/images/mobile_logo2.svg" alt="" /> Mobile Development
                        </li>
                        <li>
                            <img src="/images/laptop_logo2.svg" alt="" /> Web Development
                        </li>
                        <li>
                            <img src="/images/device_logo2.svg" alt="" /> Landing Page
                        </li>
                    </ul>
                </div> 
            </div>
            {/* Services section-2 ended ------*/}

            {/* connect section started -------*/}
            <div className="connect-section">
                <div className="connect-section-card">
                    <div>
                        <p className="connect-para">LET'S CONNECT</p>
                        <h4 className="connect-heading">Interested in discussing our services for your project?</h4>
                    </div>

                    <div>
                        <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="/images/arrow_right_alt.svg" alt="" />
                            <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                        </button>
                        <p className="calendly-para">
                            <span className="calendly-para-hide-mobile">Scheduling a call is managed by</span> <img className="calendly-logo" src="/images/calendly_logo.svg" alt="" />
                        </p>
                    </div>
                </div>
            </div>
            {/* connect section ended--------- */}

            {/* technology-section started ---------*/}
            <div className="technology-section">
                <div className="technology-card">
                    <div>
                        <p className="technology-para">TECHNOLOGIES</p>
                        <h5 className="technology-heading">Technologies we use</h5>
                    </div>
                </div>
            </div>
            {/* technology-section ended */}

            {/* work-section-1 started----*/}
            <div className="work-section-1">
                <div className="work-section-1-card-left">
                    <div>
                        <p className="work-section-1-para-blue">HOW WE WORK</p>
                        <h5 className="work-section-1-heading">Inspiring benefits awaits you</h5>
                    </div>
                    <div>
                        <p className="work-section-1-para-grey">
                        Discover the advantages of working with our design agency. We ensure productive meetings, swift iterations, and seamless handoff for your project's success.
                        </p>
                    </div>
                </div>
                <div className="work-section-1-card-right">
                    <img src="./images/work_emoji.png" alt="" />
                    <div>
                        <h5 className="work-section-1-heading-2">Productive meeting. Productive work.</h5>
                        <p className="work-section-1-para-grey-2">Efficiency unleashed. From dynamic meetings to streamlined workflows, we drive your project forward with unparalleled productivity.</p>
                    </div>
                </div>
            </div>
            {/* work section-1 ended -----*/}

            {/* work-section-2 started-------- */}
            <div className="work-section-2">
                <div className="work-section-2-card1">
                    <h5 className="work-card-heading">Understanding the requirements</h5>
                    <p className="work-card-para-grey">We're a welcoming and seasoned agency specializing in Product design and SaaS solutions.</p>
                </div>

                <div className="work-section-2-card2">
                    <h5 className="work-card-heading">Design Iterations & Development</h5>
                    <p className="work-card-para-grey">We're a welcoming and seasoned agency specializing in Product design and SaaS solutions. </p>
                </div>

                <div className="work-section-2-card3">
                    <h5 className="work-card-heading">Project <br></br>Hand off</h5>
                    <p className="work-card-para-grey">We're a welcoming and seasoned agency specializing in Product design and SaaS solutions. </p>
                </div>
            </div>
            {/* work section-2 ended */}

            {/* award section started---------- */}
            <div className="award-section">
                <div className="award-heading-container">
                    <div>
                        <p className="award-para-blue">HONURED BY LOVE</p>
                        <h6 className="award-heading">Honorable mentions & Awards</h6>
                    </div>
                    <div>
                        <img src="/images/review_tag.svg" alt="" />
                    </div>
                </div>

                <div className="award-card-container">
                    <div className="award-card1">
                        <h5 className="award-card-heading">
                            TOP USER EXPERIENCE COMPANY
                        </h5>
                    </div>

                    <div className="award-card2">
                        <h5 className="award-card-heading">
                            WORKS FEATURED ON BEHANCE
                        </h5>
                    </div>

                    <div className="award-card3">
                        <h5 className="award-card-heading">
                            TOP 1000COMPANIES GLOBAL
                        </h5>
                    </div>

                    <div className="award-card4">
                        <h5 className="award-card-heading">
                            TOP UX STRATEGY COMPANY
                        </h5>
                    </div>

                    <div className="award-card5">
                        <h5 className="award-card-heading">
                            WORKS FEATURED ON DRIBBBLE
                        </h5>
                    </div>

                    <div className="award-card6" style={awardCardDisplay}>
                        <h5 className="award-card-heading">
                            WORKS FEATURED ON DRIBBBLE
                        </h5>
                    </div>

                    <div className="award-button-container">
                        <button onClick={handleMoreAward} className="btn-award-more">{loadMoreAwardText}<img src={btnAwardIcon} alt="" /></button>
                    </div>
                </div>
            </div>
            {/* award section ended------ */}

            {/* transform section started --------*/}
            <div className="tranform-section">
                <p className="transform-section-blue-para">
                    <img src="/images/para_start_leaf.svg" alt="" /> &nbsp;&nbsp;&nbsp;WE GOT THE SOLUTIONS, FOR YOUR BRAND &nbsp;&nbsp;&nbsp;<img src="/images/para_end_leaf.svg" alt="" />
                </p>
                <h5 className="transform-section-heading">
                    Ready to transform your brand?
                </h5>
                <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="/images/arrow_right_alt.svg" alt="" />
                    <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                </button>
                <p className="calendly-para">
                    <span className="calendly-para-hide-mobile">Scheduling a call is managed by</span> <img src="/images/calendly_logo.svg" alt="" />
                </p>
            </div>
            {/* transform section ended -----------*/}

            {/* Animated section started-------------- */}
                <div className="animation-section">
                    <div className="animation-container">
                        <div className="animation-head-section">
                            <p className="animation-para-blue">WORKS PROJECTS</p>
                            <h6 className="animation-heading">Let’s see some of our Awesome works</h6>
                        </div>

                        <div className="animation-marquee-section">
                            <marquee scrollamount="5">
                                <img className="scrollingImage-1" src="/images/apple_watch.png" alt="" />
                                <img className="scrollingImage-2" src="/images/mobile1.png" alt="" />
                                <img className="scrollingImage-3" src="/images/mobile2.png" alt="" />
                                <img className="scrollingImage-4" src="/images/laptop.png" alt="" />
                                <img className="scrollingImage-1" src="/images/apple_watch.png" alt="" />
                                <img className="scrollingImage-2" src="/images/mobile1.png" alt="" />
                                <img className="scrollingImage-3" src="/images/mobile2.png" alt="" />
                                <img className="scrollingImage-4" src="/images/laptop.png" alt="" />
                                <img className="scrollingImage-1" src="/images/apple_watch.png" alt="" />
                                <img className="scrollingImage-2" src="/images/mobile1.png" alt="" />
                                <img className="scrollingImage-3" src="/images/mobile2.png" alt="" />
                                <img className="scrollingImage-4" src="/images/laptop.png" alt="" />
                                <img className="scrollingImage-1" src="/images/apple_watch.png" alt="" />
                                <img className="scrollingImage-2" src="/images/mobile1.png" alt="" />
                                <img className="scrollingImage-3" src="/images/mobile2.png" alt="" />
                                <img className="scrollingImage-4" src="/images/laptop.png" alt="" />
                                <img className="scrollingImage-1" src="/images/apple_watch.png" alt="" />
                                <img className="scrollingImage-2" src="/images/mobile1.png" alt="" />
                                <img className="scrollingImage-3" src="/images/mobile2.png" alt="" />
                                <img className="scrollingImage-4" src="/images/laptop.png" alt="" />
                                <img className="scrollingImage-1" src="/images/apple_watch.png" alt="" />
                                <img className="scrollingImage-2" src="/images/mobile1.png" alt="" />
                                <img className="scrollingImage-3" src="/images/mobile2.png" alt="" />
                                <img className="scrollingImage-4" src="/images/laptop.png" alt="" />
                                <img className="scrollingImage-1" src="/images/apple_watch.png" alt="" />
                                <img className="scrollingImage-2" src="/images/mobile1.png" alt="" />
                                <img className="scrollingImage-3" src="/images/mobile2.png" alt="" />
                                <img className="scrollingImage-4" src="/images/laptop.png" alt="" />
                                <img className="scrollingImage-1" src="/images/apple_watch.png" alt="" />
                                <img className="scrollingImage-2" src="/images/mobile1.png" alt="" />
                                <img className="scrollingImage-3" src="/images/mobile2.png" alt="" />
                                <img className="scrollingImage-4" src="/images/laptop.png" alt="" />
                            </marquee>
                        </div>
                    </div>
                </div>
            {/* Animated section ended ----------------*/}

            <div id="work-hyperLink"></div>
            {/* portfolio section started----------*/}
            <div className="portfolio-section">
                <div className="portfolio-card-container">
                    <Link className="navLink" to={`/portfolio/${workData[0].name}/${workData[0].id}`}>
                        <div className="portfolio-card1">
                            <h6 className="portfolio-card-heading">{workData[0].name} <div className="portfolio-card-tag">Mobile UI &nbsp; &nbsp;<li style={{listStylePosition:"unset"}}>Brand Identity</li></div></h6>
                            <img className="port-hover-arrow1" src="/images/portfolioHoverArrow.svg" alt="" />
                        </div> 
                    </Link>

                    <Link className="navLink" to={`/portfolio/${workData[1].name}/${workData[1].id}`}>
                        <div className="portfolio-card2">
                            <h6 className="portfolio-card-heading">{workData[1].name}<span className="portfolio-card-tag">SaaS</span></h6>
                            <img className="port-hover-arrow2" src="/images/portfolioHoverArrow.svg" alt="" />
                        </div>
                    </Link>
                </div>

                <div className="portfolio-card-container">
                    <Link className="navLink">
                        <div className="portfolio-card3" style={portCardDisplay}>
                            <h6 style={{color:"black"}} className="portfolio-card-heading">{workData[2].name} <div className="portfolio-card-tag">Mobile UI</div></h6>
                            <img className="port-hover-arrow3" src="/images/portfolioHoverCross.svg" alt="" />
                        </div>
                    </Link>

                    <Link className="navLink">
                        <div className="portfolio-card4" style={portCardDisplay}>
                            <h6 style={{color:"black"}} className="portfolio-card-heading">{workData[3].name} <span className="portfolio-card-tag">Mobile UI</span></h6>
                            <img className="port-hover-arrow4" src="/images/portfolioHoverCross.svg" alt="" />
                        </div>
                    </Link>
                </div>

                <div className="portfolio-card-container">
                    <Link className="navLink">
                        <div className="portfolio-card5" style={portCardDisplay}>
                            <h6 className="portfolio-card-heading">{workData[4].name}<div className="portfolio-card-tag">Mobile UI</div></h6>
                            <img className="port-hover-arrow5" src="/images/portfolioHoverCross.svg" alt="" />
                        </div>
                    </Link>

                    <Link className="navLink">
                        <div className="portfolio-card6" style={portCardDisplay} >
                            <h6 className="portfolio-card-heading">{workData[5].name}<span className="portfolio-card-tag">Mobile UI &nbsp; &nbsp;<li style={{listStylePosition:"unset"}}>Brand Identity</li></span></h6>
                            <img className="port-hover-arrow6" src="/images/portfolioHoverCross.svg" alt="" />
                        </div>
                    </Link>
                </div>

                <div className="portfolio-card-container">
                    <Link className="navLink" to={`/portfolio/${workData[6].name}/${workData[6].id}`}>
                        <div className="portfolio-card7" style={portCardDisplay}>
                            <h6 style={{color:"black"}} className="portfolio-card-heading">{workData[6].name} <div className="portfolio-card-tag">Mobile App &nbsp; &nbsp;<li style={{listStylePosition:"unset"}}>Web App</li></div></h6>
                            <img className="port-hover-arrow7" src="/images/portfolioHoverArrow.svg" alt="" />
                        </div>
                    </Link>

                    <Link className="navLink">
                        <div className="portfolio-card8" style={portCardDisplay} >
                            <h6 className="portfolio-card-heading">{workData[7].name} <span className="portfolio-card-tag">Visuals &nbsp; &nbsp;<li style={{listStylePosition:"unset"}}>Brand Identity</li></span></h6>
                            <img className="port-hover-arrow8" src="/images/portfolioHoverCross.svg" alt="" />
                        </div>
                    </Link>
                </div>

                <div className="portfolio-button-container">
                    <button onClick={handlePortfolioMore} className="btn-see-portfolio">{loadMorePortfolioText}<img id="dropDownArrow" src={btnPortfolioIcon} alt="" />
                        <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(255, 255, 255, 0.11)", borderRadius:"inherit"}}></div> 
                    </button>
                </div>
            </div>
            {/* portfolio section ended --------*/}

            {/* feedback-section started */}
            <div className="feedback-section">
                <div className="feedback-section-container">
                    <div className="feedback-heading-section">
                        <p className="transform-section-blue-para">
                            TESTIMONIALS &nbsp; <img src="/images/blueDott.svg" alt="" /> &nbsp; FEEDBACKS
                        </p>
                        <h6 className="feedback-heading">
                            What our clients has to say about us
                        </h6>
                        <img src="/images/review_tag.svg" alt="" />
                    </div>

                    <div className="feedback-scroller-container">
                        <div className="feedback-message-section" id="blur-card-1" onMouseOver={handleCardBlur1} onMouseOut={handleCardBlurClear}>
                            <div className="feedback-card">
                                <div className="client-profile-section">
                                    <img className="client-profile-pic" src="/images/ganesh_pic.png"></img>
                                    <span>
                                        <p className="client-name">Jai Ganesh</p>
                                        <p className="client-designation">VP, Devlok</p>
                                    </span>
                                </div>

                                <div className="client-message-section">
                                    <p className="client-message">
                                        "I highly recommend DzgnMacha! 😊 Being a small business owner, it's challenging to find a design agency that understands your vision. DzgnMacha not only understood my needs but also delivered exceptional designs that have received countless compliments. 💖 Their prices are reasonable, and their quality of work is outstanding. 👌✨"
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img src="/images/proflowIcon.svg" alt="" />
                            </div>
                        </div>

                        <div className="feedback-message-section" id="blur-card-2" onMouseOver={handleCardBlur2} onMouseOut={handleCardBlurClear}>
                            <div className="feedback-card">
                                <div className="client-profile-section">
                                    <img className="client-profile-pic" src="/images/ganesh_pic.png"></img>
                                    <span>
                                        <p className="client-name">Jai Ganesh</p>
                                        <p className="client-designation">VP, Devlok</p>
                                    </span>
                                </div>

                                <div className="client-message-section">
                                    <p className="client-message">
                                        "I highly recommend DzgnMacha! 😊 Being a small business owner, it's challenging to find a design agency that understands your vision. DzgnMacha not only understood my needs but also delivered exceptional designs that have received countless compliments. 💖 Their prices are reasonable, and their quality of work is outstanding. 👌✨"
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img src="/images/proflowIcon.svg" alt="" />
                            </div>
                        </div>

                        <div className="feedback-message-section" id="blur-card-3" onMouseOver={handleCardBlur3} onMouseOut={handleCardBlurClear}>
                            <div className="feedback-card" id="blur-card-3">
                                <div className="client-profile-section">
                                    <img className="client-profile-pic" src="/images/mahan_pic.png"></img>
                                    <span>
                                        <p className="client-name">Mahan</p>
                                        <p className="client-designation">Marketing, Salesforce</p>
                                    </span>
                                </div>

                                <div className="client-message-section">
                                    <p className="client-message">
                                        "DzgnMacha is absolutely fantastic! 😍✨ As a small business owner, finding a design agency that truly gets your brand can be tough. Luckily, I found DzgnMacha and they exceeded all my expectations. Their team was attentive, professional, and delivered designs that perfectly captured my brand's essence. 🙌💼"
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img src="/images/destance_icon.svg" alt="" />
                            </div>
                        </div>

                        <div className="feedback-message-section" id="blur-card-4" onMouseOver={handleCardBlur4} onMouseOut={handleCardBlurClear}>
                            <div className="feedback-card">
                                <div className="client-profile-section">
                                    <img className="client-profile-pic" src="/images/mahan_pic.png"></img>
                                    <span>
                                        <p className="client-name">Amaya</p>
                                        <p className="client-designation">Marketing Salesforce</p>
                                    </span>
                                </div>

                                <div className="client-message-section">
                                    <p className="client-message">
                                    "For small business owners seeking design services, choose DzgnMacha! 💼 They understood my brand from the start, delivering designs that exceeded my expectations. Their professionalism, attention to detail, and reasonable prices make them stand out. 👌✨"
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img src="./images/fourthright_icon.svg" alt="" />
                            </div>
                        </div>

                        <div className="feedback-message-section" id="blur-card-5" onMouseOver={handleCardBlur5} onMouseOut={handleCardBlurClear}>
                            <div className="feedback-card">
                                <div className="client-profile-section">
                                    <img className="client-profile-pic" src="/images/mahan_pic.png"></img>
                                    <span>
                                        <p className="client-name">Devashish</p>
                                        <p className="client-designation">Web Developer</p>
                                    </span>
                                </div>

                                <div className="client-message-section">
                                    <p className="client-message">
                                    "DzgnMacha is a game-changer! 🎮 As a small business owner, I struggled to find a design agency that could bring my vision to life until I discovered DzgnMacha. Their team was attentive, professional, and delivered designs that perfectly represented my brand. 🌟 I've received so many compliments on their work! 👏"                                    
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img src="/images/coco_icon.svg" alt="" />
                            </div>
                        </div>

                        <div className="feedback-message-section" id="blur-card-6" onMouseOver={handleCardBlur6} onMouseOut={handleCardBlurClear}>
                            <div className="feedback-card">
                                <div className="client-profile-section">
                                    <img className="client-profile-pic" src="./images/mahan_pic.png"></img>
                                    <span>
                                        <p className="client-name">Jaysurya</p>
                                        <p className="client-designation">UI/UX Designer</p>
                                    </span>
                                </div>

                                <div className="client-message-section">
                                    <p className="client-message">
                                        "I highly recommend DzgnMacha! 😊 Being a small business owner, it's challenging to find a design agency that understands your vision. DzgnMacha not only understood my needs but also delivered exceptional designs that have received countless compliments. 💖 Their prices are reasonable, and their quality of work is outstanding. 👌✨"
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img src="/images/proflowIcon.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Feedback section ended ------ */}

            {/* transform section started --------*/}
            <div className="tranform-section">
                <p className="transform-section-blue-para">
                    <img src="/images/para_start_leaf.svg" alt="" /> &nbsp;&nbsp;&nbsp;WHAT ARE YOU THINKING? LET’S CONNECT &nbsp;&nbsp;&nbsp;<img src="/images/para_end_leaf.svg" alt="" />
                </p>
                <h5 className="transform-section-heading" style={{width:"1095px"}}>
                    Your brand deserves <br></br><span className="heading-gradient">a better site.</span> Let's talk.
                </h5>
                <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="/images/arrow_right_alt.svg" alt="" />
                    <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                </button>
                <p className="calendly-para">
                    <span className="calendly-para-hide-mobile">Scheduling a call is managed by</span> <img className="calendly-logo" src="/images/calendly_logo.svg" alt="" />
                </p>
            </div>
            {/* transform section ended -----------*/}
            <div id="blogs"></div>

            {/* blog section----- */}
            <div className="blog-section">
                <div className="blog-section-container">
                    <div className="blog-heading-container">
                        <p className="blog-para-blue">BLOGS</p>
                        <h5 className="blog-heading">Title related to blogs section</h5>
                    </div>
                    <div className="blog-cards-container">
                        {
                            blogdetails.map((item)=>
                            <div className="blog-card1" key={item.id}>
                                <Link to={`/blog/${item.title}/${item.id}`} className="navLink">
                                    <img src={item.thumbnail} width="100%" height="339"></img>
                                    <h6 className="blog-card-title">
                                    {item.title}
                                    </h6>

                                    <div className="blog-card-rating-row">
                                        <div className="rate-tag">
                                        <img src="/images/star_white.svg" alt="" />{item.rate}
                                        </div>
                                        <div className="rate-date">
                                            {item.month}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            )
                        }
                    </div>
                    {/* <div className="blog-card-more-button">
                        <button onClick={handleBlogCardMore} className="btn-blogcard-more">{loadMoreBlogText} <img src={btnBlogIcon} alt="" /></button>
                    </div> */}
                </div>
            </div>
            {/* blog section ended -------- */}

        </div>
    )
} 