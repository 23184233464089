import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import blogaData from "./blogdata.json";

export function Blog(props){
    const [blogs, setBlogs] = useState([]);
    const [relatedBlog, setRelatedBlog] = useState([]);
    const params = useParams();

    function sortData() {
        const selectedBlog = blogaData.find(item => item.title === params.blogtitle);
        const relatedBlogs = blogaData.filter(item => item.title !== params.blogtitle);

        if (selectedBlog) {
            setBlogs(selectedBlog);
        }
        setRelatedBlog(relatedBlogs);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        sortData();
    }, [params]);

    return(

        <div>
            {/* blog section started --------*/}
            <div className="blog-details-section">
                {/* blog details left column started --- */}
                <div className="blog-details-left-column">
                    <div className="blog-left-head-container">
                        <p className="blog-blue-tag"><span>{blogs.blogtag}</span> &nbsp; <span>Facts</span> <button className="blog-share-button-mobile">Share <img src="/images/share_icon.svg" alt="" /></button></p>
                        
                        <h4 className="blog-details-heading">{blogs.title}</h4>
                        <div className="share-blog-container">
                            <p style={{display:"flex"}}>{blogs.month} &nbsp; &nbsp;<li style={{listStylePosition:"inside"}}>DesignMacha</li></p>
                            <div className="blog-rate-share-container">
                                <button className="blog-share-button">Share <img src="/images/share_icon.svg" alt="" /></button>
                                &nbsp; &nbsp;<span className="blog-rate"><img src="/images/rate_star.svg" alt="" /> &nbsp; &nbsp;{blogs.rate}</span>
                            </div>
                        </div>
                    </div>

                    <div className="blog-details-body-container">
                        <p className="read-here-para">Read here</p>
                        <p className="blog-intro-para">{blogs.intropara}</p>

                        <div className="blog-content-table-section">
                            <div className="blog-content-table-container">
                                <p className="table-content-para-blue">TABLE CONTENT</p>
                                <ul>
                                    <li>{blogs.point1}</li>
                                    <li>{blogs.point2}</li>
                                    <li>{blogs.point3}</li>
                                    <li>{blogs.point4}</li>
                                    <li>{blogs.point5}</li>
                                    <li>{blogs.point6}</li>
                                    <li>{blogs.point7}</li>
                                    <li>{blogs.point8}</li>
                                    {blogs.point9 && <li className="point9" style={{ display: blogs.point9 ? 'list-item' : 'none' }}>{blogs.point9}</li>}
                                    {blogs.point10 && <li className="point10" style={{ display: blogs.point10 ? 'list-item' : 'none' }}>{blogs.point10}</li>}
                                    {blogs.point11 && <li className="point11" style={{ display: blogs.point11 ? 'list-item' : 'none' }}>{blogs.point11}</li>}
                                </ul>
                            </div>
                        </div>

                        <div className="topic1-container">
                            <h6 className="blog-topic1-heading">{blogs.topic1title}</h6>
                            <p className="blog-topic1-para">{blogs.topic1para}</p>

                            <li className="point1">{blogs.point1}</li>
                            <p className="point1-para">{blogs.point1para}</p>

                            <li className="point2">{blogs.point2}</li>
                            <p className="point2-para">{blogs.point2para}</p>

                            <h6 id="topic2" className="blog-topic2-heading">{blogs.topic2title}</h6>
                            <p id="topicPara2" className="blog-topic2-para">{blogs.topic2para}</p>

                            <li className="point3">{blogs.point3}</li>
                            <p className="point3-para">{blogs.point3para}</p>

                            <li className="point4">{blogs.point4}</li>
                            <p className="point4-para">{blogs.point4para}</p>

                            <li className="point5">{blogs.point5}</li>
                            <p className="point5-para">{blogs.point5para}</p>

                            <h6 className="blog-topic3-heading">{blogs.topic3title}</h6>
                            <p className="blog-topic3-para">{blogs.topic3para}</p>

                            <li className="point6">{blogs.point6}</li>
                            <p className="point6-para">{blogs.point6para}</p>

                            <li className="point7">{blogs.point7}</li>
                            <p className="point7-para">{blogs.point7para}</p>

                            <li className="point8">{blogs.point8}</li>
                            <p className="point8-para">{blogs.point8para}</p>

                            <h6 className="blog-topic4-heading">{blogs.topic4title}</h6>
                            <p className="blog-topic4-para">{blogs.topic4para}</p>

                            {blogs.point9 && <li className="point9" style={{ display: blogs.point9 ? 'list-item' : 'none' }}>{blogs.point9}</li>}
                            {blogs.point9para && <p className="point9-para" style={{ display: blogs.point9para ? 'block' : 'none' }}>{blogs.point9para}</p>}

                            {blogs.point10 && <li className="point10" style={{ display: blogs.point10 ? 'list-item' : 'none' }}>{blogs.point10}</li>}
                            {blogs.point10para && <p className="point10-para" style={{ display: blogs.point10para ? 'block' : 'none' }}>{blogs.point10para}</p>}

                            {blogs.point11 && <li className="point11" style={{ display: blogs.point11 ? 'list-item' : 'none' }}>{blogs.point11}</li>}
                            {blogs.point11para && <p className="point11-para" style={{ display: blogs.point11para ? 'block' : 'none' }}>{blogs.point11para}</p>}
                        </div>
                    </div>
                </div>
                {/* blog details left column ended ---- */}

                {/* blog details right column started --- */}
                <div className="blog-details-right-column">
                    <div className="related-blogs-container">
                        {
                            relatedBlog.map((item)=>
                                <div className="related-blog-card" key={item.id}>
                                    <Link style={{textDecoration:"none"}} to={`/blog/${item.title}/${item.id}`}>
                                        <h6 className="related-card-heading">{item.title}</h6>
                                        <div className="share-blog-container">
                                            <div className="blog-rate-share-container">
                                                <span className="blog-rate"><img src="/images/rate_star.svg" alt="" /> &nbsp; &nbsp;{item.rate}</span>
                                            </div>
                                            <p style={{display:"flex"}}>{item.month}</p>                                
                                        </div>
                                    </Link>                            
                                </div>
                            )
                        }
                    </div>

                    <div className="blog-shcedule-call-card">
                         <div>
                            <img width="100%" src="/images/schedule-card-imag.png" alt="" />
                         </div>

                         <div>
                            <p className="blog-schedule-call-para-blue">LET'S TALK</p>
                            <h6 className="blog-schedule-call-heading">Intersted in discussing our services for your SaaS project</h6>
                            <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="./images/arrow_right_alt.svg" alt="" />
                                <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                            </button>
                            <p className="calendly-para" style={{justifyContent:"end"}}>
                                <img src="/images/calendly_logo_white.svg" alt="" />
                            </p>
                         </div>
                    </div>
                </div>
                {/* blog details right column ended ---- */}
            </div>



            {/* blog section----- */}
            <div className="blog-section">
                <div className="blog-section-container">
                    <div className="blog-heading-container">
                        <p className="blog-para-blue">BLOGS</p>
                        <h5 className="blog-heading">Title related to blogs section</h5>
                    </div>
                    <div className="blog-cards-container">
                        {
                            blogaData.map((item)=>
                            <div className="blog-card1">
                                <Link to={`/blog/${item.title}/${item.id}`} className="navLink">
                                    <img src={item.thumbnail} width="100%" height="339"></img>
                                    <h6 className="blog-card-title">
                                    {item.title}
                                    </h6>

                                    <div className="blog-card-rating-row">
                                        <div className="rate-tag">
                                        <img src="/images/star_white.svg" alt="" />{item.rate}
                                        </div>
                                        <div className="rate-date">
                                            {item.month}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            )
                        }
                    </div>
                    {/* <div className="blog-card-more-button">
                        <button onClick={handleBlogCardMore} className="btn-blogcard-more">{loadMoreBlogText} <img src={btnBlogIcon} alt="" /></button>
                    </div> */}
                </div>
            </div>
            {/* blog section ended -------- */}
        </div>
    )
}