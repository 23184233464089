import { useEffect } from "react";

export function Career(props){
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return(
        <div>
            <div className="hero-section">
                <p className="hero-section-blue-para">
                <img src="/images/para_start_leaf.svg" alt="" /> &nbsp;&nbsp;&nbsp;We’re ready and honored to have you &nbsp;&nbsp;&nbsp;<img src="/images/para_end_leaf.svg" alt="" />
                </p>

                <div className="hero-section-heading">
                    <h1>Let’s make designs together as a team</h1>
                </div> 
                <div className="hero-section-heading-mobile">
                    <h1>Let’s make designs together as a team</h1>
                </div>

                <div className="hero-section-grey-para">
                    <p>Our skilled product designers make great solutions easy. We help committed brands bring their ideas to life smoothly. Choose us for a simple and effective design experience that boosts your brand.</p>
                </div>

                <div className="hero-section-buttons">
                    <a style={{display:"none"}}><button id="btnExplorPricing">Explore Pricings</button></a>{/* -- dont remove this button*/}
                    <a href="#opening" className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center", textDecoration:"none"}} onMouseOver={props.addSmoothScroll} onMouseOut={props.removeSmoothScroll}  >Explore Roles <img src="/images/arrow_right_alt.svg" alt="" />
                        <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                    </a>
                </div>
            </div>
            {/* hero section ended ---------*/}

            {/* scrolling section started--------- */}
            <marquee className="hiring-scrolling-section">
                <div>
                    <h3>We are Hiring ! &nbsp; We are Hiring ! &nbsp; We are Hiring ! &nbsp; We are Hiring ! &nbsp; We are Hiring ! &nbsp; We are Hiring !&nbsp; We are Hiring ! &nbsp; We are Hiring !</h3>
                </div>
            </marquee>
            {/* scrolling  section ended ----------*/}

            <div  id="opening">

            </div>

            {/* opportunities section started------- */}
            <div className="opportunity-section">
                <div className="opportunity-card">
                    <div>
                        <div>
                            <p className="opportunity-para-blue">OPENINIGS</p>
                            <h4 className="opportunity-heading">Opportunities<br></br> opened for you</h4>
                        </div>
                        <div>
                            <p className="opportunity-para-grey">
                                We're a welcoming and seasoned agency specializing in Product design. We're a diverse team of digital product specialists, including seasoned product managers, designers, developers, and business analysts.
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src="/images/opportunityImage.png" alt="" />
                        <img src="/images/opportunityImageMobile.png" alt="" />
                    </div>
                </div>
            </div>
            {/* opportunity section ended------- */}

            {/* oppening card section started -------- */}
            <div className="opening-card-section">
                <a href="#">
                    <div className="opening-card">
                        <div>
                            <p className="opening-card-para-blue">OPENING ONE</p>
                            <div className="opening-card-tag">On-site</div>
                        </div>
                        <div>
                            <h6 className="designation">UI UX Designer</h6>
                            <p className="role">Design</p>
                        </div>
                        <div>
                            <p className="opening-para-grey">
                                1Position
                            </p>
                            <img className="opening-logo" src="/images/opening-logo.svg" alt="" />
                        </div>

                        <div className="immediatText">
                            Immediate Join
                        </div>
                    </div>
                </a>

                <a href="#">
                    <div className="opening-card">
                        <div>
                            <p className="opening-card-para-blue">OPENING TWO</p>
                            <div className="opening-card-tag">On-site</div>
                        </div>
                        <div>
                            <h6 className="designation">framer Dev</h6>
                            <p className="role">Development-Front End</p>
                        </div>
                        <div>
                            <p className="opening-para-grey">
                                1Position
                            </p>
                            <img className="opening-logo" src="/images/opening-logo.svg" alt="" />
                        </div>
                    </div>
                </a>

                <a href="#">
                    <div className="opening-card">
                        <div>
                            <p className="opening-card-para-blue">OPENING THREE</p>
                            <div className="opening-card-tag">On-site</div>
                        </div>
                        <div>
                            <h6 className="designation">Webflow Dev</h6>
                            <p className="role">Development - Front End</p>
                        </div>
                        <div>
                            <p className="opening-para-grey">
                                1Position
                            </p>
                            <img className="opening-logo" src="/images/opening-logo.svg" alt="" />
                        </div>
                    </div>
                </a>

                <a href="#">
                    <div className="opening-card">
                        <div>
                            <p className="opening-card-para-blue">OPENING FOUR</p>
                            <div className="opening-card-tag">On-site</div>
                        </div>
                        <div>
                            <h6 className="designation">Wordpress Dev</h6>
                            <p className="role">Development - Front End</p>
                        </div>
                        <div>
                            <p className="opening-para-grey">
                                1Position
                            </p>
                            <img  className="opening-logo" src="/images/opening-logo.svg" alt="" />
                        </div>
                    </div>
                </a>

                <a href="#">
                    <div className="opening-card">
                        <div>
                            <p className="opening-card-para-blue">OPENING FIVE</p>
                            <div className="opening-card-tag">On-site</div>
                        </div>
                        <div>
                            <h6 className="designation">Talent Acquisition</h6>
                            <p className="role">HR - Company</p>
                        </div>
                        <div>
                            <p className="opening-para-grey">
                                1Position
                            </p>
                            <img  className="opening-logo" src="/images/opening-logo.svg" alt="" />
                        </div>
                        <div className="immediatText">
                            Immediate Join
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}