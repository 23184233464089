import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import workdata from "./workdata.json"



export function Portfolio(props){
    const[workdetails, setWorkDetails] = useState([]);
    const[relatedWork, setRelatedWork] = useState([]);
    const params = useParams();

    function setWordData() {
        const selectedWork = workdata.find(item => item.name === params.workname);
        const relatedWork = workdata.filter(item => item.name !== params.workname);

        if (selectedWork) {
            setWorkDetails(selectedWork);
        }
        setRelatedWork(relatedWork);
    }

    useEffect(()=>{
        window.scrollTo(0, 0); 
        setWordData();
    }, [params]);
    return(
        <div>
            {/* <div className="work-nav-container">
                <button className="portfolio-close-button"><img src="/images/arrow_left_alt.svg" alt="" />BACK</button>
                <button className="portfolio-close-button-mobile"><img src="/images/cross.svg" alt="" /></button>
                <button onClick={props.scheduleCall} className="portfolio-next-button">Schedule a Call<img src="/images/arrow_right_alt.svg" alt="" /></button>
            </div> */}

            <div className="work-head-section">
                <h6 className="work-head-section-heading">{workdetails.title}</h6>
                <p className="work-head-section-para">{workdetails.mainPara}</p>

                <div className="work-logo-container">
                    <div>
                        <img src={workdetails.workLogo} alt="" />
                    </div>
                    <div>
                        (Scroll)
                    </div>
                </div>
            </div>
            {/* work-head-section ended ------ */}

            {/* work show card started -------- */}
            <div className="work-show-card-section">
                <div className="work-show-card1" style={{backgroundImage:`url(${workdetails.showCard1})`, backgroundSize:"cover", backgroundPosition:"center"}}>

                </div>
                <div className="work-show-card2" style={{backgroundImage:`url(${workdetails.showCard2})`, backgroundSize:"cover", backgroundPosition:"center"}}>

                </div>


                {/* For Mobile view */}
                <div className="work-show-card1-mobile" style={{backgroundImage:`url(${workdetails.showCard1Mobile})`, backgroundSize:"cover", backgroundPosition:"center"}}>

                </div>
                <div className="work-show-card2-mobile" style={{backgroundImage:`url(${workdetails.showCard2Mobile})`, backgroundSize:"cover", backgroundPosition:"center"}}>

                </div>
            </div>
            {/* work show card ended -------- */}

            {/* work type section started --- */}
            <div className="work-type-section">
                <h6 className="work-type-heading">{workdetails.workType}</h6>

                <p className="work-type-para">{workdetails.workTypePara}</p>
            </div>
            {/* work type section ended --- */}

            {/* hexa color section started ----- */}
            <div className="hexa-color-section">
                <div className="color-container">
                    <div style={{backgroundColor:`${workdetails.hexaColor1}`}} className="color1-card">
                        <div className="hexacode-1">
                            HEX CODE {workdetails.hexaCode1}<br></br>
                            RGB
                        </div>
                    </div>
                    <div style={{backgroundColor:`${workdetails.hexaColor2}`}} className="color2-card">
                        <div className="hexacode-2">
                            HEX CODE {workdetails.hexaCode1}<br></br>
                            RGB
                        </div>
                    </div>
                    <div style={{backgroundColor:`${workdetails.hexaColor3}`}} className="color3-card">
                        <div className="hexacode-3">
                            HEX CODE {workdetails.hexaCode1}<br></br>
                            RGB
                        </div>
                    </div>
                </div>
            </div>
            {/* hexa color section ended ----- */}

            {/* home page section started ----- */}
            <div className="work-home-page-section">
                <div className="work-home-page-card" style={{backgroundImage:`url(${workdetails.homepageBgImage})`}}>
                    <div className="work-home-page-head-section">
                        <p className="work-home-page-para-blue">SCREEN</p>
                        <h5 className="work-home-page-heading"><span>{workdetails.name}</span><br></br>Homepage</h5>
                        <p className="work-home-page-para-grey">{workdetails.homepagePara}</p>
                    </div>
                </div>

                {/* for mobile view  */}
                <div className="work-home-page-card-mobile" style={{backgroundImage:`url(${workdetails.homepageBgImageMobile})`}}>
                    <div className="work-home-page-head-section-mobile">
                        <p className="work-home-page-para-blue-mobile">SCREEN</p>
                        <h5 className="work-home-page-heading-mobile"><span>{workdetails.name}</span><br></br>Homepage</h5>
                        <p className="work-home-page-para-grey-mobile">{workdetails.homepagePara}</p>
                    </div>
                </div>
            </div>
            {/* home page section ended ----- */}

            {/* work-banner-section started ------ */}
            <div className="work-banner-section">
                <div className="work-banner-card">
                    <div className="work-card-img">
                        <img src={workdetails.bannerImg1} alt="" />
                    </div>
                    <div className="work-card-img">
                        <img src={workdetails.bannerImg2} alt="" />
                    </div>
                    <div className="work-card-img">
                        <img src={workdetails.bannerImg3} alt="" />
                    </div>
                    <div className="work-card-img">
                        <img src={workdetails.bannerImg4} alt="" />
                    </div>
                </div>
            </div>
            {/* work-banner-section ended -------- */}

            {/* project-poster-section started ---- */}
            <div className="project-poster-section">
                <div className="project-poster-head-section">
                    <p className="project-poster-para-blue">SCREEN</p>
                    <h5 className="project-poster-heading">{workdetails.projectPosterTitle}</h5>
                    <p className="project-poster-para-grey">Experience the perfect design of Destance, enhanced with smooth micro-interactions and animations. Enjoy a seamless and engaging running experience with friends, setting goals, tracking progress, and staying motivated together.</p>
                </div>
                <div className="poster-card" style={{backgroundImage:`url(${workdetails.projectPoster})`}}>

                </div>

                {/* for mobile view  */}
                <div className="poster-card-mobile" style={{backgroundImage:`url(${workdetails.projectPosterMobile})`}}>

                </div>
            </div>
             {/* project-poster-section ended ---- */}

             {/* related project card-section started --- */}
             <div className="related-project-card-section">
                <div className="related-project-card-head-section">
                    <p className="related-work-para-blue">WORKS</p>
                    <h5 className="related-work-heading">Explore more works from us</h5>
                </div>

                <div className="related-card-container">
                    {
                        relatedWork.map((item)=> 
                            <Link key={item.id} className="navLink related-card"  style={{backgroundImage:`url(${item.thumbnail})`}} to={`/portfolio/${item.name}/${item.id}`}>
                                <div>
                                    <h6 className="portfolio-card-heading">{item.name} <div className="portfolio-card-tag">Mobile UI &nbsp; &nbsp;<li style={{listStylePosition:"unset"}}>Brand Identity</li></div></h6>
                                </div>
                            </Link>
                        )
                    }
                </div>
             </div>
             {/* related project card section ended  */}
             <div>

             </div>

            {/* transform section started --------*/}
            <div className="tranform-section">
                <p className="transform-section-blue-para">
                    <img src="./images/para_start_leaf.svg" alt="" /> &nbsp;&nbsp;&nbsp;WHAT ARE YOU THINKING? LET’S CONNECT &nbsp;&nbsp;&nbsp;<img src="./images/para_end_leaf.svg" alt="" />
                </p>
                <h5 className="transform-section-heading" style={{width:"1095px"}}>
                    Your brand deserves <br></br><span className="heading-gradient">a better site.</span> Let's talk.
                </h5>
                <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="./images/arrow_right_alt.svg" alt="" />
                    <div style={{position:"absolute", height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                </button>
                <p className="calendly-para">
                    <span className="calendly-para-hide-mobile">Scheduling a call is managed by</span> <img className="calendly-logo" src="/images/calendly_logo.svg" alt="" />
                </p>
            </div>
            <div className="work-page-footer-devider">
                    
            </div>
            {/* transform section ended -----------*/}

        </div>
    )
}