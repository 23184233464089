import { useEffect, useState } from "react";

export function WhiteLabel(props){
    const[viewMoreBenefitsDisplay, setViewMoreBenefitsDisplay] = useState({display:"none"})
    const[viewMoreBenefitsText, setViewMoreBenefitsText] = useState("View More Benefits")
    const[viewMoreBenfitsIcon, setViewMoreBenefitsIcon] = useState("/images/arrow_drop_down.svg")
    const[servicePrice, setServicePrice] = useState("");


    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    function handleServiceType(e){
        if(e.target.value==="DESIGN"){
            setServicePrice(499.00)
        }else if(e.target.value==="DEVELOPMENT"){
            setServicePrice(999.00)
        } else if(e.target.value==="MARKETING AGENCIES"){
            setServicePrice(699.00)
        }else  if(e.target.value==="ALL"){
            setServicePrice(1899.00)
        }
    }

    function handleViewBenefits(){
        if(viewMoreBenefitsText==="View More Benefits"){
            setViewMoreBenefitsText("View Less")
            setViewMoreBenefitsDisplay({display:"flex"})
            setViewMoreBenefitsIcon("/images/arrow_drop_up.svg")
        } else{
            setViewMoreBenefitsText("View More Benefits")
            setViewMoreBenefitsDisplay({display:"none"})
            setViewMoreBenefitsIcon("/images/arrow_drop_down.svg")
        }
    }

    return( 
        <div>
            <div className="hero-section">
                <p className="hero-section-blue-para">
                    <img src="/images/para_start_leaf.svg" alt="" /> &nbsp;&nbsp;&nbsp;Experienced Product Designers for &nbsp;  <span className="saas-tag">SaaS</span> <span className="agencies-tag">Agencies</span> &nbsp;&nbsp;&nbsp;<img src="/images/para_end_leaf.svg" alt="" />
                </p>

                <div className="hero-section-heading">
                    <h1>Let’s make designs together as a team</h1>
                </div>
                <div className="hero-section-heading-mobile">
                    <h1>Let’s make designs together as a team</h1>
                </div>

                <div className="hero-section-grey-para">
                    <p>Our skilled product designers make great solutions easy. We help committed brands bring their ideas to life smoothly. Choose us for a simple and effective design experience that boosts your brand.</p>
                </div>

                <div className="hero-section-service-para-blue">
                    <p>
                        Trusted by 250+ Agencies all across the Globe.
                    </p>
                    <p>
                        Get your Clients’ Projects Done.
                    </p>
                    <p>
                        Start assigning tasks from today.
                    </p>
                </div>

                <div className="hero-section-buttons">
                <a className="btnExlopreDesktop" style={{textDecoration:"none", color:"rgba(255, 255, 255, 1)"}} href="#price-chart"><button id="btnExplorPricing">Explore Pricings</button></a>
                <a className="btnExlopreMobile" style={{textDecoration:"none", color:"rgba(255, 255, 255, 1)"}} href="#pricechart"><button id="btnExplorPricing">Explore Pricings</button></a>
                    <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="/images/arrow_right_alt.svg" alt="" />
                        <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                    </button>
                </div>
            </div>
            {/* hero section ended ---------*/}

            {/* service section started--------- */}
            <div className="white-label-service-section">
                <div className="white-label-service-section-container">
                    <div>
                        <p className="white-label-service-section-para-blue">OUR WHITE LABEL SERVICES</p>
                        <h5 className="white-label-service-section-heading">Explore our various White label services</h5>
                        <p className="white-label-service-section-para-grey">We're a welcoming and seasoned agency specializing in Product design and SaaS solutions. We're a diverse team of digital product specialists, including seasoned product managers, designers, developers, and business analysts.</p>
                        <ul className="white-label-service-list">
                            <li><img src="/images/wordpress_logo.svg" alt="" />Wordpress</li>
                            <li><img src="/images/globe_logo.svg" alt="" />Websites Design</li>
                            <li><img src="/images/highlighter.svg" alt="" />SEO Optimization</li>
                            <li><img src="/images/shopify_logo.svg" alt="" />Shopify page</li>
                            <li><img src="/images/router_logo.svg" alt="" />Hosting server setup & Maintainance</li>
                        </ul>
                    </div>

                    <div>
                        <img src="/images/tech_logos.png" alt="" />
                    </div>
                </div>
            </div>
            {/* service section ended ---------------*/}

            {/* benefits section started */}
            <div className="white-label-benefit-section">
                <p className="white-label-benefit-para-blue">
                    BENEFITS 
                </p>
                <h5 className="white-label-benefit-heading">Choosing us as your white label partner has lot of benefits</h5>
            </div>
            {/* service benefits section ended------- */}

            {/* white lable card section started------- */}
            <div className="white-label-card-section">
                <div className="white-label-card-section-row-1">
                    <div className="white-label-card">
                        <div>500+</div>
                        <p>DESIGNS DELIVERED<br></br> TO THE CLIENTS</p>
                    </div>
                    <div className="white-label-card">
                        <div>40+</div>
                        <p>POWER PACKED<br></br>DESIGNERS</p>
                    </div>
                    <div className="white-label-card">
                        <div>3+</div>
                        <p>EXPERIENCE<br></br> IN THE FIELD</p>
                    </div>
                    <div className="white-label-card">
                        <img src="/images/block_icon.svg" alt="" />
                        <p>NO HIRING. NO WAITING.<br></br> NO TRAINING</p>
                    </div>
                    <div className="white-label-card">
                        <img  src="/images/newspaper_icon.svg" alt="" />
                        <p>NO DURATION LIMITS OR<br></br> CONTRACTS</p>
                    </div>

                    {/* card for mobile view only  */}
                    <div id="white-label-mobile-card1" className="white-label-card">
                        <img  src="/images/shield_icon.svg" alt="" />
                        <p>PROTECTED WITH<br></br>NDA AGREEMENT</p>
                    </div>
                    <div style={viewMoreBenefitsDisplay} id="white-label-mobile-card2" className="white-label-card">
                        <div>1000+</div>
                        <p>NO DURATION LIMITS OR <br></br>CONTRACTS</p>
                    </div>
                    <div  style={viewMoreBenefitsDisplay} id="white-label-mobile-card3" className="white-label-card">
                        <img  src="/images/cable_icon.svg" alt="" />
                        <p>PROTECTED WITH<br></br>NDA AGREEMENT</p>
                    </div>
                    <div  style={viewMoreBenefitsDisplay} id="white-label-mobile-card4" className="white-label-card">
                        <img  src="/images/screwDriver_icon.svg" alt="" />
                        <p>YOUR CHOICE OF<br></br>PM TOOL CONTRACTS</p>
                    </div>
                    <div  style={viewMoreBenefitsDisplay} id="white-label-mobile-card5" className="white-label-card">
                        <img  src="/images/photo_icon.svg" alt="" />
                        <p>SCALABILITY<br></br>AT ITS BEST</p>
                    </div>
                </div>

                <div className="white-label-card-section-row-2">
                    <div className="white-label-card">
                        <img  src="/images/shield_icon.svg" alt="" />
                        <p>PROTECTED WITH<br></br>NDA AGREEMENT</p>
                    </div>
                    <div className="white-label-card">
                        <div>1000+</div>
                        <p>NO DURATION LIMITS OR <br></br>CONTRACTS</p>
                    </div>
                    <div className="white-label-card">
                        <img  src="/images/cable_icon.svg" alt="" />
                        <p>PROTECTED WITH<br></br>NDA AGREEMENT</p>
                    </div>
                    <div className="white-label-card">
                        <img  src="/images/screwDriver_icon.svg" alt="" />
                        <p>YOUR CHOICE OF<br></br>PM TOOL CONTRACTS</p>
                    </div>
                    <div className="white-label-card">
                        <img  src="/images/photo_icon.svg" alt="" />
                        <p>SCALABILITY<br></br>AT ITS BEST</p>
                    </div>
                </div>

                <div className="load-more-benefits">
                    <button onClick={handleViewBenefits} className="btn-benefits-more" style={{width:"209px"}}>{viewMoreBenefitsText} &nbsp; &nbsp;  <img src={viewMoreBenfitsIcon} alt="" />
                        <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(255, 255, 255, 0.11)", borderRadius:"inherit"}}></div> 
                    </button>
                </div>
            </div>
            {/* white label card section ended---- */}

            {/* white-label-Pricing section started -------*/}
            <div className="white-label-pricing-section" id="price-chart">
                <div className="white-label-pricing-heading-section">
                    <p className="white-label-para-blue">
                        PRICING & PACKAGES
                    </p>
                    <h6 className="white-label-pricing-heading">
                        White Label Pricing
                    </h6>
                </div>

                <table className="white-label-pricing-table" style={{width:"100%"}} border>
                    <thead>
                        <tr>
                            <th>

                            </th>
                            <th>
                                <div className="white-label-table-head-tag">DESIGN</div>
                                <div className="white-label-price">$ 499.00<span>/month</span></div>
                                <p className="cancel-anytime-para"><img src="/images/info.svg" alt="" />Cancel Anytime</p>
                            </th>
                            <th>
                                <div className="white-label-table-head-tag">MARKETING AGENCIES</div>
                                <div className="white-label-price">$ 699.00<span>/month</span></div>
                                <p className="cancel-anytime-para"><img src="/images/info.svg" alt="" />Cancel Anytime</p>
                            </th>
                            <th>
                                <div className="white-label-table-head-tag">DEVELOPMENT</div>
                                <div className="white-label-price">$ 999.00<span>/month</span></div>
                                <p className="cancel-anytime-para"><img src="/images/info.svg" alt="" />Cancel Anytime</p>
                            </th>
                            <th>
                                <div className="white-label-table-head-tag">ALL</div>
                                <div className="white-label-price">$ 1899.00<span>/month</span></div>
                                <p className="cancel-anytime-para"><img src="/images/info.svg" alt="" />Cancel Anytime</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>APP<br></br>DESIGN</td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                        </tr>
                        <tr>
                            <td>LNADING<br></br>PAGE DESIGN</td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td><img src="/images/close.svg" alt="" /></td>
                        </tr>
                        <tr>
                            <td>TECH<br></br>STACK</td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td style={{display:"flex", alignItems:"center"}}><img src="/images/wordpress_logo_blue.svg" alt="" style={{marginRight:"5px"}} /><img src="/images/info.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" style={{marginRight:"5px"}} /><img src="/images/info.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                        </tr>
                        <tr>
                            <td>MOBILE<br></br>DESIGN</td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                        </tr>
                        <tr>
                            <td>WEB SUPPORT &<br></br>MAINTAINANCE</td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                        </tr>
                        <tr>
                            <td>FULL<br></br>PROJECTS</td>
                            <td>2</td>
                            <td>5</td>
                            <td>7</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>UNLIMITED<br></br>SEO</td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                        </tr>
                        <tr>
                            <td>STACK<br></br>SUPPORT</td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                        </tr>
                        <tr>
                            <td>PROGRESS<br></br>CALL</td>
                            <td>MONTHLY</td>
                            <td>WEEKLY</td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td>WEEKLY</td>
                        </tr>
                        <tr>
                            <td>REPORT</td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                        </tr>
                        <tr>
                            <td>DEDICATED<br></br>ACCOUNT<br></br>MANAGER</td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                        </tr>
                        <tr>
                            <td>PROJECT<br></br>MANAGER</td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                        </tr>
                        <tr>
                            <td>1 ON 1<br></br>MEETINGS</td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/check.svg" alt="" /></td>
                            <td><img src="/images/close.svg" alt="" /></td>
                            <td><img src="/images/close.svg" alt="" /></td>
                        </tr>
                    </tbody>
                </table>
                <div className="white-label-subscribe-section">
                    <div>
                        <p className="white-label-subscribe-para-blue">SUBSCRIBE</p>
                        <h6 className="white-label-subscribe-heading">Ready to Subscribe to a plan? Assign your first task today</h6>
                    </div>
                    <div>
                        <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="/images/arrow_right_alt.svg" alt="" /></button>
                        <p className="calendly-para">
                            Scheduling a call is managed by <img src="/images/calendly_logo.svg" alt="" />
                        </p>
                    </div>
                </div>
            </div>


            <div id="pricechart"></div>
            {/* white label pricing section mobile started  */}
            <div className="white-label-pricing-section-mobile">
                <div className="white-label-pricing-heading-section">
                    <p className="white-label-para-blue">
                        PRICING & PACKAGES
                    </p>
                    <h6 className="white-label-pricing-heading">
                        White Label Pricing
                    </h6>
                </div>

                <div className="pricing-card-mobile">
                    <div className="pricing-card-head-section">
                        <select onChange={handleServiceType} className="btnDesign">
                            <option>
                                DESIGN
                            </option>
                            <option>
                                DEVELOPMENT
                            </option>
                            <option>
                                MARKETING AGENCIES
                            </option>
                            <option>
                                ALL
                            </option>
                        </select>
                        <div className="white-label-price"><sup>$</sup> {servicePrice}<span>/month</span></div>
                        <p className="cancel-anytime-para"><img src="/images/info.svg" alt="" />Cancel Anytime</p>
                    </div>

                    <div className="white-label-available-service-section">
                        <ul>
                            <li><img src="/images/check_mobile.svg" alt="" />ADS DESIGN</li>
                            <li><img src="/images/check_mobile.svg" alt="" />LANDING PAGE DESIGN</li>
                            <li><img src="/images/check_mobile.svg" alt="" />MOBILE DESIGN</li>
                            <li><img src="/images/check_mobile.svg" alt="" />WEB SUPPORT & MAINTAINANCE</li>
                            <li><img src="/images/check_mobile.svg" alt="" />FULL PROJECTS</li>
                            <li><img src="/images/check_mobile.svg" alt="" />FULL PROJECTS & WEBSITE</li>
                            <li><img src="/images/check_mobile.svg" alt="" />UNLIMITED SEO</li>
                            <li><img src="/images/check_mobile.svg" alt="" />SLACK SUPPORT</li>
                            <li><img src="/images/check_mobile.svg" alt="" />SLACK SUPPORT</li>
                            <li><img src="/images/check_mobile.svg" alt="" />REPORT</li>
                            <li><img src="/images/check_mobile.svg" alt="" />DEDICATED ACCOUNT MANAGER</li>
                            <li><img src="/images/check_mobile.svg" alt="" />PROJECTS MANAGER</li>
                            <li><img src="/images/check_mobile.svg" alt="" />1 ON 1 MEETINGS</li>
                        </ul>
                    </div>

                    <div className="pricing-card-subscribe-section">
                        <p className="white-label-subscribe-para-blue">SUBSCRIBE</p>
                        <h6 className="white-label-subscribe-heading">Ready for<br></br> Subscribe to a<br></br> plan? <br></br>Assign your first <br></br>task today</h6>
                        <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="/images/arrow_right_alt.svg" alt="" />
                            <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                        </button>
                        <p style={{textAlign:"end"}}>
                            <img className="calendly-logo" src="/images/calendly_logo.svg" alt="" />
                        </p>
                    </div>
                </div>
            </div>

            {/* connect section for desktop  */}
            <div className="white-label-connect-section-desktop">
                <div className="white-label-connect-section-desktop-card">
                    <div>
                        <p className="white-label-subscribe-para-blue">LET’S CONNECT</p>
                        <h6 className="white-label-subscribe-heading">Looking for a <br></br>custom<br></br> plan for your,<br /> business?</h6>
                    </div>
                    <div>
                        <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="/images/arrow_right_alt.svg" alt="" />
                            <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                        </button>
                        <p style={{textAlign:"end"}}>
                           Scheduling a call is managed by <img className="calendly-logo" src="/images/calendly_logo.svg" alt="" />
                        </p>
                    </div>
                </div>
            </div>
            {/* connect section for desktop  */}

            {/* connect section started -------*/}
            <div className="white-label-connect-section">
                <div className="pricing-card-subscribe-section" style={{borderRadius:"16px"}}>
                    <div>
                        <p className="white-label-subscribe-para-blue">LET’S CONNECT</p>
                    <h6 className="white-label-subscribe-heading">Looking for a <br></br>custom<br></br> plan for your,<br /> business?</h6>
                    </div>
                    <button onClick={props.scheduleCall} className="btn-schedule-call-blue" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Schedule a call <img src="/images/arrow_right_alt.svg" alt="" />
                        <div style={{position:"absolute",height:"inherit", width:"inherit",boxShadow: "0px 4px 5px rgba(198, 173, 255, 0.21)", borderRadius:"inherit"}}></div> 
                    </button>
                    <p style={{textAlign:"end"}}>
                        <img className="calendly-logo" src="/images/calendly_logo.svg" alt="" />
                    </p>
                </div>
            </div>
            {/* connect section ended--------- */}
        </div>
    )
}