import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Home } from "./home";
import { WhiteLabel } from "./whitelabel";
import { Portfolio } from "./portfolio";
import { Career } from "./career";
import { Blog } from "./blog";
import { useEffect, useState } from "react";
import LocomotiveScroll from 'locomotive-scroll';
const locomotiveScroll = new LocomotiveScroll();

export function Index(){
    const[contactUsStyle, setContactUsStyle] = useState({width:"80%", height:"100vh", transition:"1s", backgroundColor:"transparent", position:"fixed", right:"-100%", top:"0px", zIndex:"2"})
    const[contactUsMobileStyle, setContactUsMobileStyle] = useState({width:"100%", height:"100%", transition:"1s", backgroundColor:"transparent", position:"fixed", bottom:"-100%", zIndex:"2"})
    const[overlayBlack, setOverlayBlack] = useState({display:"none", transition:"0.5", position:"fixed", height:"100vh", width:"100%", backgroundColor:"rgba(8, 8, 8, 0.778)"})

    const[mobileMenuStyle, setMobileMenuStyle] = useState({top:"-100%"})

    const[errorMessage, setErrorMessage] = useState({"fNameError":"", "lNameError":"","emailError":"", "contactError":""});
    const[loadingDisplay, setLoadingDisplay] = useState({display:"none"})

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    
    function openMobileMenu(){
        setMobileMenuStyle({top:"0%", transition:"1s"})
    }
    function closeMobileMenu(){
        setMobileMenuStyle({top:"-100%", transition:"1s"})
    }

    function openContactUsForm(e){
        e.preventDefault();
        setContactUsStyle({width:"80%", transition:"1s",  height:"100vh", backgroundColor:"transparent", position:"fixed", right:"0px", top:"0px", zIndex:"2"})
        setContactUsMobileStyle({width:"100%", height:"100%", transition:"1s", backgroundColor:"transparent", position:"fixed", bottom:"-20%", zIndex:"2"})
        setOverlayBlack({display:"flex", transition:"0.5", position:"fixed", height:"100vh", width:"100%", backgroundColor:"rgba(8, 8, 8, 0.778)"})
    }

    function closeContactUsForm(e){
        e.preventDefault();
        setContactUsStyle({width:"80%", transition:"1s", height:"100vh", backgroundColor:"transparent", position:"fixed", right:"-100%", top:"0px", zIndex:"2"})
        setContactUsMobileStyle({width:"100%", height:"100%", transition:"1s", backgroundColor:"transparent", position:"fixed", bottom:"-100%", zIndex:"2"})
        setOverlayBlack({display:"none", transition:"0.5", position:"fixed", height:"100vh", width:"100%", backgroundColor:"rgba(8, 8, 8, 0.778)"})
    }

    function scheduleCall(e){
        e.preventDefault()
        window.open("https://calendly.com/designmachallp/30min", "_blank");
    }

    function handleBehanceCheck(){
        document.getElementById("optBehance").checked="true"
        document.getElementById("source-field1").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("source-field2").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field3").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field4").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field5").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field6").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleDrippleCheck(){
        document.getElementById("optDripple").checked="true"
        document.getElementById("source-field1").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field2").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("source-field3").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field4").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field5").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field6").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleLinkedinCheck(){
        document.getElementById("optLinkedin").checked="true"
        document.getElementById("source-field1").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field2").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field3").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("source-field4").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field5").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field6").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleWorldOfMouthCheck(){
        document.getElementById("optWorldOfMouth").checked="true"
        document.getElementById("source-field1").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field2").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field3").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field4").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("source-field5").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field6").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleGoogleSearchCheck(){
        document.getElementById("optGoogleSerach").checked="true"
        document.getElementById("source-field1").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field2").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field3").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field4").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field5").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("source-field6").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleOtherSourceCheck(){
        document.getElementById("optOtherSource").checked="true"
        document.getElementById("source-field1").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field2").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field3").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field4").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field5").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field6").style.borderColor="rgba(172, 143, 239, 1)"
    }
    // -----------


    function handleWebDevelopmentCheck(){
        document.getElementById("optWebsite").checked="true"
        document.getElementById("service-field1").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("service-field2").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field3").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field4").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleMobileAppCheck(){
        document.getElementById("optMobileApp").checked="true"
        document.getElementById("service-field1").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field2").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("service-field3").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field4").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleCollaborationCheck(){
        document.getElementById("optCollaboration").checked="true"
        document.getElementById("service-field1").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field2").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field3").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("service-field4").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleOtherServiceCheck(){
        document.getElementById("optOtherService").checked="true"
        document.getElementById("service-field1").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field2").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field3").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field4").style.borderColor="rgba(172, 143, 239, 1)"
    }

    // for mobile logic -----
    function handleBehanceCheckMobile(){
        document.getElementById("optBehanceMobile").checked="true"
        document.getElementById("source-field1Mobile").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("source-field2Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field3Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field4Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field5Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field6Mobile").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleDrippleCheckMobile(){
        document.getElementById("optDrippleMobile").checked="true"
        document.getElementById("source-field1Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field2Mobile").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("source-field3Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field4Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field5Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field6Mobile").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleLinkedinCheckMobile(){
        document.getElementById("optLinkedinMobile").checked="true"
        document.getElementById("source-field1Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field2Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field3Mobile").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("source-field4Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field5Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field6Mobile").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleWorldOfMouthCheckMobile(){
        document.getElementById("optWorldOfMouthMobile").checked="true"
        document.getElementById("source-field1Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field2Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field3Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field4Mobile").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("source-field5Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field6Mobile").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleGoogleSearchCheckMobile(){
        document.getElementById("optGoogleSerachMobile").checked="true"
        document.getElementById("source-field1Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field2Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field3Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field4Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field5Mobile").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("source-field6Mobile").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleOtherSourceCheckMobile(){
        document.getElementById("optOtherSourceMobile").checked="true"
        document.getElementById("source-field1Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field2Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field3Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field4Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field5Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("source-field6Mobile").style.borderColor="rgba(172, 143, 239, 1)"
    }
    // -----------
    
    // for mobile logic ------
    function handleWebDevelopmentCheckMobile(){
        document.getElementById("optWebsiteMobile").checked="true"
        document.getElementById("service-field1Mobile").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("service-field2Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field3Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field4Mobile").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleMobileAppCheckMobile(){
        document.getElementById("optMobileAppMobile").checked="true"
        document.getElementById("service-field1Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field2Mobile").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("service-field3Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field4Mobile").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleCollaborationCheckMobile(){
        document.getElementById("optCollaborationMobile").checked="true"
        document.getElementById("service-field1Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field2Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field3Mobile").style.borderColor="rgba(172, 143, 239, 1)"
        document.getElementById("service-field4Mobile").style.borderColor="rgba(40, 40, 40, 1)"
    }
    function handleOtherServiceCheckMobile(){
        document.getElementById("optOtherServiceMobile").checked="true"
        document.getElementById("service-field1Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field2Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field3Mobile").style.borderColor="rgba(40, 40, 40, 1)"
        document.getElementById("service-field4Mobile").style.borderColor="rgba(172, 143, 239, 1)"
    }
    
    // contact submit logic for desktop -------
    async function handleFormSubmit(e){
        e.preventDefault();
        var firstname = document.getElementById("txtFirstName");
        var lastname = document.getElementById("txtLastName");
        var emailaddress = document.getElementById("txtEmail");
        var contact = document.getElementById("txtContact");

        if(firstname.value===""){
            firstname.focus();
            setErrorMessage({"fNameError":"Please enter first name", "lNameError":"", "emailError":"", "contactError":""})
        } else if(lastname.value===""){
            lastname.focus();
            setErrorMessage({"fNameError":"", "lNameError":"Please enter last name", "emailError":"", "contactError":""})
        } else if(emailaddress.value===""){
            emailaddress.focus();
            setErrorMessage({"fNameError":"", "lNameError":"", "emailError":"Please enter email adress", "contactError":""})
        } else if(contact.value===""){
            contact.focus();
            setErrorMessage({"fNameError":"", "lNameError":"","emailError":"", "contactError":"Please enter contact number"})
        } else{
            const scriptURL = "https://script.google.com/macros/s/AKfycbz67NoZfTHTXjFRMzOFPorZaRvVm_bJozIVR38Ocltjndi0W8qwD-H3qX32scyuNewazA/exec"
            const form = document.forms["contact-details"]
            setLoadingDisplay({display:"flex"})
            await fetch(scriptURL, {method: "POST", body: new FormData(form)})
            .catch(error => console.error("Error!", error.message));
            window.location.href = "/";
            setLoadingDisplay({display:"none"}); 
        }
    }


    // contact submit logic for mobile -------
    async function handleFormSubmitMobile(e){
        e.preventDefault();
        var firstnameMobile = document.getElementById("txtFirstNameMobile");
        var lastnameMobile = document.getElementById("txtLastNameMobile");
        var emailaddressMobile = document.getElementById("txtEmailMobile");
        var contactMobile = document.getElementById("txtContactMobile");

        if(firstnameMobile.value===""){
            firstnameMobile.focus();
            setErrorMessage({"fNameError":"Please enter first name", "lNameError":"", "emailError":"", "contactError":""})
        } else if(lastnameMobile.value===""){
            lastnameMobile.focus();
            setErrorMessage({"fNameError":"", "lNameError":"Please enter last name", "sourceError":"", "serviceError":"", "emailError":"", "contactError":""})
        } else if(emailaddressMobile.value===""){
            emailaddressMobile.focus();
            setErrorMessage({"fNameError":"", "lNameError":"", "sourceError":"", "serviceError":"", "emailError":"Please enter email adress", "contactError":""})
        } else if(contactMobile.value===""){
            contactMobile.focus();
            setErrorMessage({"fNameError":"", "lNameError":"", "sourceError":"", "serviceError":"", "emailError":"", "contactError":"Please enter contact number"})
        } else{
            const scriptURL = "https://script.google.com/macros/s/AKfycbz67NoZfTHTXjFRMzOFPorZaRvVm_bJozIVR38Ocltjndi0W8qwD-H3qX32scyuNewazA/exec"
            const form = document.forms["contact-details-mobile"]
            setLoadingDisplay({display:"flex"})
            await fetch(scriptURL, {method: "POST", body: new FormData(form)})
            .catch(error => console.error("Error!", error.message))
            window.location.href = "/";
            setLoadingDisplay({display:"none"}); 
        }
    }

    function handleHeaderHome(){
        window.scrollTo(0, 0);
        document.getElementById("btnHeaderHome").style.backgroundColor="rgba(23, 23, 23, 1)";
        document.getElementById("btnHeaderServices").style.backgroundColor="transparent"
        document.getElementById("btnHeaderWhiteLabel").style.backgroundColor="transparent"
        document.getElementById("btnHeaderWork").style.backgroundColor="transparent"
        document.getElementById("btnHeaderBlogs").style.backgroundColor="transparent"
        document.getElementById("btnHeaderCareer").style.backgroundColor="transparent"

        document.getElementById("btnHeaderHome").style.color="rgba(255, 255, 255, 1)";
        document.getElementById("btnHeaderServices").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderWhiteLabel").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderWork").style.color="rgba(153, 153, 153, 1)";
        document.getElementById("btnHeaderBlogs").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderCareer").style.color="rgba(153, 153, 153, 1)"
    }
    function handleHeaderServices(){
        document.getElementById("btnHeaderHome").style.backgroundColor="transparent";
        document.getElementById("btnHeaderServices").style.backgroundColor="rgba(23, 23, 23, 1)"
        document.getElementById("btnHeaderWhiteLabel").style.backgroundColor="transparent"
        document.getElementById("btnHeaderWork").style.backgroundColor="transparent"
        document.getElementById("btnHeaderBlogs").style.backgroundColor="transparent"
        document.getElementById("btnHeaderCareer").style.backgroundColor="transparent"

        document.getElementById("btnHeaderHome").style.color="rgba(153, 153, 153, 1)";
        document.getElementById("btnHeaderServices").style.color="rgba(255, 255, 255, 1)"
        document.getElementById("btnHeaderWhiteLabel").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderWork").style.color="rgba(153, 153, 153, 1)";
        document.getElementById("btnHeaderBlogs").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderCareer").style.color="rgba(153, 153, 153, 1)"
    }
    function handleHeaderWhiteLabel(){
        window.scrollTo(0, 0);
        document.getElementById("btnHeaderHome").style.backgroundColor="transparent"
        document.getElementById("btnHeaderServices").style.backgroundColor="transparent"
        document.getElementById("btnHeaderWhiteLabel").style.backgroundColor="rgba(23, 23, 23, 1)"
        document.getElementById("btnHeaderWork").style.backgroundColor="transparent"
        document.getElementById("btnHeaderBlogs").style.backgroundColor="transparent"
        document.getElementById("btnHeaderCareer").style.backgroundColor="transparent"

        document.getElementById("btnHeaderWhiteLabel").style.color="rgba(255, 255, 255, 1)"
        document.getElementById("btnHeaderHome").style.color="rgba(153, 153, 153, 1)";
        document.getElementById("btnHeaderServices").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderWork").style.color="rgba(153, 153, 153, 1)";
        document.getElementById("btnHeaderBlogs").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderCareer").style.color="rgba(153, 153, 153, 1)"
    }
    function handleHeaderWork(){
        document.getElementById("btnHeaderHome").style.backgroundColor="transparent"
        document.getElementById("btnHeaderServices").style.backgroundColor="transparent"
        document.getElementById("btnHeaderWhiteLabel").style.backgroundColor="transparent"
        document.getElementById("btnHeaderWork").style.backgroundColor="rgba(23, 23, 23, 1)"
        document.getElementById("btnHeaderBlogs").style.backgroundColor="transparent"
        document.getElementById("btnHeaderCareer").style.backgroundColor="transparent"

        document.getElementById("btnHeaderWork").style.color="rgba(255, 255, 255, 1)";
        document.getElementById("btnHeaderHome").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderServices").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderWhiteLabel").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderBlogs").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderCareer").style.color="rgba(153, 153, 153, 1)"
    }
    function handleHeaderBlogs(){
        document.getElementById("btnHeaderHome").style.backgroundColor="transparent"
        document.getElementById("btnHeaderServices").style.backgroundColor="transparent"
        document.getElementById("btnHeaderWhiteLabel").style.backgroundColor="transparent"
        document.getElementById("btnHeaderWork").style.backgroundColor="transparent"
        document.getElementById("btnHeaderBlogs").style.backgroundColor="rgba(23, 23, 23, 1)"
        document.getElementById("btnHeaderCareer").style.backgroundColor="transparent"

        document.getElementById("btnHeaderWork").style.color="rgba(153, 153, 153, 1)";
        document.getElementById("btnHeaderHome").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderServices").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderWhiteLabel").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderBlogs").style.color="rgba(255, 255, 255, 1)"
        document.getElementById("btnHeaderCareer").style.color="rgba(153, 153, 153, 1)"
    }
    function handleHeaderCareer(){
        window.scrollTo(0, 0);
        document.getElementById("btnHeaderHome").style.backgroundColor="transparent"
        document.getElementById("btnHeaderServices").style.backgroundColor="transparent"
        document.getElementById("btnHeaderWhiteLabel").style.backgroundColor="transparent"
        document.getElementById("btnHeaderWork").style.backgroundColor="transparent"
        document.getElementById("btnHeaderBlogs").style.backgroundColor="transparent"
        document.getElementById("btnHeaderCareer").style.backgroundColor="rgba(23, 23, 23, 1)"

        document.getElementById("btnHeaderWork").style.color="rgba(153, 153, 153, 1)";
        document.getElementById("btnHeaderHome").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderServices").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderWhiteLabel").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderBlogs").style.color="rgba(153, 153, 153, 1)"
        document.getElementById("btnHeaderCareer").style.color="rgba(255, 255, 255, 1)"
    }

    function addSmoothScroll(){
        document.querySelector("html").style.scrollBehavior="smooth"
    }
    function removeSmoothScroll(){
        document.querySelector("html").style.scrollBehavior="unset"
    }
    return(
        <div>
            {/* Loading-container started-------*/}
            <div className="loading-container" style={loadingDisplay}>
                <div className="loader"></div>
                <div style={{margin:"20px", textAlign:"center"}}>
                    Loading...
                </div>
            </div>
            {/* Loading-container ended ---------*/}

            <BrowserRouter>
            <header className="desktop-header">
                <div className="header-nav-container">
                    <Link onClick={()=>{window.scrollTo(0,0)}} className="navLink" to="/"><img className="header-logo" src="/images/siteLogo.svg"></img></Link>
                    <Link id="btnNavLink" className="navLink" to="/"><button onClick={handleHeaderHome} id="btnHeaderHome">Home</button></Link>
                    <a className="navLink" href="/#services"><button onClick={handleHeaderServices} id="btnHeaderServices" onMouseOut={removeSmoothScroll} onMouseOver={addSmoothScroll}>Services</button></a>
                    <Link className="navLink" to="/whiteLabel"><button onClick={handleHeaderWhiteLabel} id="btnHeaderWhiteLabel">White Label</button></Link>
                    <a className="navLink" href="/#work-hyperLink"><button onClick={handleHeaderWork} id="btnHeaderWork"  onMouseOut={removeSmoothScroll} onMouseOver={addSmoothScroll}>Work</button></a>
                    <a className="navLink" href="/#blogs"><button onClick={handleHeaderBlogs} id="btnHeaderBlogs"  onMouseOut={removeSmoothScroll} onMouseOver={addSmoothScroll}>Blogs</button></a>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <Link className="navLink" to="/career"><button  onClick={handleHeaderCareer} style={{display:"flex", alignItems:"center"}} id="btnHeaderCareer">Career &nbsp;  &nbsp; <div className="hiring-tag">We are hiring!</div></button></Link>
                    </div>
                    <button onClick={scheduleCall} id="btn-schedule-call-black">Schedule a Call <img src="/images/arrow_right_alt.svg"></img>
                        <div style={{width:"inherit", position:"absolute", height:"inherit",boxShadow: "0px 4px 5px rgba(255, 255, 255, 0.11)", borderRadius:"inherit"}}>
                        </div>
                    </button>
                </div>
            </header>

            {/* mobile view header ---- */}
            <header className="mobile-header">
                <div className="header-nav-container-mobile">
                    <Link onClick={()=>{window.scrollTo(0,0)}} className="navLink" to="/"><img className="header-logo" src="/images/siteLogoMobile.svg"></img></Link>

                    <button onClick={openMobileMenu} id="btnMenu"><img src="/images/menu.svg"></img></button>
                </div>
            </header>
            {/* Header ended-----------------*/}

            {/* mobile header menu ---- */}
            <div className="mobile-header-menu" style={mobileMenuStyle}>
                <div className="mobile-header-menu-head-section">
                    <img src="/images/siteLogoMobile.svg" alt="" />
                    <button onClick={closeMobileMenu} className="btn-close-menu">
                        <img src="/images/close_menu.svg" alt="" />
                    </button>
                </div>

                <div className="mobile-header-menu-body">
                    <button onClick={closeMobileMenu} className="btn-mobile-nav"><Link id="btnNavLink" className="navLinkMobile" to="/">Home</Link></button>
                    <button onClick={closeMobileMenu} className="btn-mobile-nav"><a onMouseOut={removeSmoothScroll} onMouseOver={addSmoothScroll} className="navLinkMobile" href="/#services">Services</a></button>
                    <button onClick={closeMobileMenu} className="btn-mobile-nav"><Link className="navLinkMobile" to="/whiteLabel">White Label</Link></button>
                    <button onClick={closeMobileMenu} className="btn-mobile-nav"><a onMouseOut={removeSmoothScroll} onMouseOver={addSmoothScroll} className="navLinkMobile"  href="/#work-hyperLink" >Work</a></button>
                    <button onClick={closeMobileMenu} className="btn-mobile-nav"><a onMouseOut={removeSmoothScroll} onMouseOver={addSmoothScroll} className="navLinkMobile" href="/#blogs">Blogs</a></button>
                    <div style={{display:"flex", alignItems:"center"}}>
                        <button onClick={closeMobileMenu} className="btn-mobile-nav" style={{display:"flex", alignItems:"center"}} id="btnHeaderCareer"><Link className="navLinkMobile" to="/career">Career</Link> &nbsp;  &nbsp; <div className="hiring-tag">We are hiring!</div></button>
                    </div>
                </div>

                <button style={{background:"linear-gradient(rgba(0, 0, 0, 1), rgba(25, 25, 25, 1))", height:"47px"}} onClick={scheduleCall} id="btn-schedule-call-black">Schedule a Call <img src="/images/arrow_right_alt.svg"></img>
                    <div style={{width:"inherit", position:"absolute", height:"inherit",boxShadow: "0px 4px 5px rgba(255, 255, 255, 0.11)", borderRadius:"inherit"}}>

                    </div>
                </button>
            </div>
            {/* mobile header menu  ended---- */}

            <div style={overlayBlack}  onClick={closeContactUsForm}>

            </div>

            {/* contact us form started---------- */}
            <div className="contact-form-container" style={contactUsStyle}>
                <form name="contact-details" className="contact-form" onSubmit={handleFormSubmit}>
                    <div className="contact-form-nav">
                        <button className="close-button" onClick={closeContactUsForm}><img src="./images/arrow_left_alt.svg" alt="" />BACK</button>
                        <button className="close-button-mobile" onClick={closeContactUsForm}><img src="./images/cross.svg" alt="" /></button>
                        <button onClick={scheduleCall}>Schedule a Call<img src="./images/arrow_right_alt.svg" alt="" /></button>
                    </div>

                    <h3 className="contact-us-form-heading">Let's Collaborate Let the magic Happen</h3>

                    <a className="email-link" href="mailto:hello@dzgnmacha.com">
                        <p>hello@dzgnmacha.com <img src="/images/email_arrow.svg" alt="" /></p>
                    </a>

                    <p className="contact-us-form-para-blue">
                        OF FILL THIS INSTEAD
                    </p>

                    <div className="name-field">
                        <div style={{width:"100%"}}>
                            <input name="firstname" type="text" id="txtFirstName" placeholder="FirstName"></input>
                            <label>{errorMessage.fNameError}</label>
                        </div>
                        <div style={{width:"100%"}}>
                            <input name="lastname" type="text" id="txtLastName" placeholder="LarstName"></input>
                            <label>{errorMessage.lNameError}</label>
                        </div>
                    </div>

                    <div className="radio-field">
                        <p className="radio-para">You found us from?</p>
                        <div className="radio-field-row">
                            <div id="source-field1" onClick={handleBehanceCheck}>
                               <input name="foundUsSource" type="radio" id="optBehance" value="Behance" />Behance
                            </div>

                            <div id="source-field2" onClick={handleDrippleCheck}>
                               <input name="foundUsSource" type="radio" id="optDripple" value="Dripple"/>Dribbble
                            </div>

                            <div id="source-field3" onClick={handleLinkedinCheck}>
                               <input name="foundUsSource" type="radio" id="optLinkedin" value="Linkedin"/>LinkedIn
                            </div>

                            <div id="source-field4" onClick={handleWorldOfMouthCheck}>
                               <input name="foundUsSource" type="radio" id="optWorldOfMouth" value="WorldOfMouth" />World of mouth
                            </div>

                            <div id="source-field5" onClick={handleGoogleSearchCheck}>
                               <input name="foundUsSource" type="radio" id="optGoogleSerach" value="GoogleSearch"/>Google Search
                            </div>

                            <div id="source-field6" onClick={handleOtherSourceCheck}>
                               <input name="foundUsSource" type="radio" id="optOtherSource" value="Other"/>Other
                            </div>
                        </div>
                    </div>

                    <div className="radio-field">
                        <p className="radio-para">You’re looking for help with</p>
                        <div className="radio-field-row">
                            <div id="service-field1" onClick={handleWebDevelopmentCheck}>
                               <input name="service" type="radio" id="optWebsite" value="WebsiteDevelopment" />Website
                            </div>

                            <div id="service-field2" onClick={handleMobileAppCheck}>
                               <input name="service" type="radio" id="optMobileApp" value="MobileAppDevelopment" />Mobile app design
                            </div>

                            <div id="service-field3" onClick={handleCollaborationCheck}>
                               <input name="service" type="radio" id="optCollaboration" value="Collaboration"/>Collaboration
                            </div>

                            <div id="service-field4" onClick={handleOtherServiceCheck}>
                               <input name="service" type="radio" id="optOtherService" value="Others"/>Others
                            </div>
                        </div>
                    </div>

                    <div className="contact-field">
                        <div style={{width:"100%"}}>
                            <input name="email" type="email" id="txtEmail" placeholder="Email Address"></input>
                            <label>{errorMessage.emailError}</label>
                        </div>
                        <div style={{width:"100%"}}>
                            <input name="contact" type="text" id="txtContact" placeholder="Contact Number"></input>
                            <label>{errorMessage.contactError}</label>
                        </div>
                    </div>

                    <div className="message-field">
                        <textarea style={{resize:"none"}} placeholder="Tell more details about your project" id="txtMessage"></textarea>
                    </div>

                    <div>
                        <button className="btnSubmitBlue">Submit <img src="./images/arrow_right_alt.svg" alt="" /></button>
                    </div>

                    <p className="contact-us-para-grey">
                        <li>We’ll reach out to you as soon as we received your mail, Thank you for the query.</li>
                    </p>

                    <div className="contact-us-footer">
                        <p className="left-para">©DzgnMacha 2023 - 2024</p>
                        <p className="right-para">Designed in Bengaluru, India <img src="./images/indian_flag.svg" alt="" /></p>
                    </div>
                </form>
            </div>
            {/* contactus form ended-------- */}


            {/* contact us form for mobile started---------- */}
            <div className="contact-form-container-mobile" style={contactUsMobileStyle}>
                <form name="contact-details-mobile" className="contact-form" onSubmit={handleFormSubmitMobile}>
                    <div className="contact-form-nav">
                        <button className="close-button" onClick={closeContactUsForm}><img src="/images/arrow_left_alt.svg" alt="" />BACK</button>
                        <button className="close-button-mobile" onClick={closeContactUsForm}><img src="/images/cross.svg" alt="" /></button>
                        <button onClick={scheduleCall}>Schedule a Call<img src="/images/arrow_right_alt.svg" alt="" /></button>
                    </div>

                    <h3 className="contact-us-form-heading">Let's Collaborate Let the magic Happen</h3>

                    <a className="email-link" href="mailto:hello@dzgnmacha.com">
                        <p>hello@dzgnmacha.com <img src="/images/email_arrow.svg" alt="" /></p>
                    </a>

                    <p className="contact-us-form-para-blue">
                        OF FILL THIS INSTEAD
                    </p>

                    <div className="name-field">
                        <div style={{width:"100%"}}>
                            <input name="firstname" type="text" id="txtFirstNameMobile" placeholder="FirstName"></input>
                            <label>{errorMessage.fNameError}</label>
                        </div>
                        <div style={{width:"100%"}}>
                            <input name="lastname" type="text" id="txtLastNameMobile" placeholder="LarstName"></input>
                            <label>{errorMessage.lNameError}</label>
                        </div>
                    </div>

                    <div className="radio-field">
                        <p className="radio-para">You found us from?</p>
                        <div className="radio-field-row">
                            <div id="source-field1Mobile" onClick={handleBehanceCheckMobile}>
                               <input name="foundUsSource" type="radio" id="optBehanceMobile" value="Behance" />Behance
                            </div>

                            <div id="source-field2Mobile" onClick={handleDrippleCheckMobile}>
                               <input name="foundUsSource" type="radio" id="optDrippleMobile" value="Dripple"/>Dribbble
                            </div>

                            <div id="source-field3Mobile" onClick={handleLinkedinCheckMobile}>
                               <input name="foundUsSource" type="radio" id="optLinkedinMobile" value="Linkedin"/>LinkedIn
                            </div>

                            <div id="source-field4Mobile" onClick={handleWorldOfMouthCheckMobile}>
                               <input name="foundUsSource" type="radio" id="optWorldOfMouthMobile" value="WorldOfMouth" />World of mouth
                            </div>

                            <div id="source-field5Mobile" onClick={handleGoogleSearchCheckMobile}>
                               <input name="foundUsSource" type="radio" id="optGoogleSerachMobile" value="GoogleSearch"/>Google Search
                            </div>

                            <div id="source-field6Mobile" onClick={handleOtherSourceCheckMobile}>
                               <input name="foundUsSource" type="radio" id="optOtherSourceMobile" value="Other"/>Other
                            </div>
                        </div>
                    </div>

                    <div className="radio-field">
                        <p className="radio-para">You’re looking for help with</p>
                        <div className="radio-field-row">
                            <div id="service-field1Mobile" onClick={handleWebDevelopmentCheckMobile}>
                               <input name="service" type="radio" id="optWebsiteMobile" value="WebsiteDevelopment" />Website
                            </div>

                            <div id="service-field2Mobile" onClick={handleMobileAppCheckMobile}>
                               <input name="service" type="radio" id="optMobileAppMobile" value="MobileAppDevelopment" />Mobile app design
                            </div>

                            <div id="service-field3Mobile" onClick={handleCollaborationCheckMobile}>
                               <input name="service" type="radio" id="optCollaborationMobile" value="Collaboration"/>Collaboration
                            </div>

                            <div id="service-field4Mobile" onClick={handleOtherServiceCheckMobile}>
                               <input name="service" type="radio" id="optOtherServiceMobile" value="Others"/>Others
                            </div>
                        </div>
                    </div>

                    <div className="contact-field">
                        <div style={{width:"100%"}}>
                            <input name="email" type="email" id="txtEmailMobile" placeholder="Email Address"></input>
                            <label>{errorMessage.emailError}</label>
                        </div>
                        <div style={{width:"100%"}}>
                            <input name="contact" type="text" id="txtContactMobile" placeholder="Contact Number"></input>
                            <label>{errorMessage.contactError}</label>
                        </div>
                    </div>

                    <div className="message-field">
                        <textarea style={{resize:"none"}} placeholder="Tell more details about your project" id="txtMessage"></textarea>
                    </div>

                    <div>
                        <button className="btnSubmitBlue">Submit <img src="/images/arrow_right_alt.svg" alt="" /></button>
                    </div>

                    <p className="contact-us-para-grey">
                        <li>We’ll reach out to you as soon as we received your mail, Thank you for the query.</li>
                    </p>

                    <div className="contact-us-footer">
                        <p className="left-para">©DzgnMacha 2023 - 2024</p>
                        <p className="right-para">Designed in Bengaluru, India <img src="/images/indian_flag.svg" alt="" /></p>
                    </div>
                </form>
            </div>
            {/* contactus form ended-------- */}

            {/* portfolio page stylesheet started --------- */}
             <Routes>
                <Route path="/" element={<Home scheduleCall={scheduleCall} openContactForm={openContactUsForm}/>}></Route>
                {/* <Route path="/home" element={<Home scheduleCall={scheduleCall} openContactForm={openContactUsForm}/>}></Route> */}
                <Route path="/whiteLabel" element={<WhiteLabel scheduleCall={scheduleCall}/>}></Route>
                <Route path="/portfolio/:workname/:workid" element={<Portfolio scheduleCall={scheduleCall}/>}></Route>
                <Route path="/career" element={<Career addSmoothScroll={addSmoothScroll} removeSmoothScroll={removeSmoothScroll} />}></Route>
                <Route path="/blog/:blogtitle/:blogid" element={<Blog scheduleCall={scheduleCall}/>}></Route>
             </Routes>

             {/* footer started------- */}
             <footer>
                <div className="footer-container">
                    <div className="footer-nav-container">
                        <div>
                            <Link className="navLink" onClick={()=>{window.scrollTo(0,0)}}><img src="/images/siteLogo.svg" alt="" /></Link>
                            <div className="footer-navbar">
                                <button onClick={()=>{window.scrollTo(0,0)}}><Link className="navLink" to="/" style={{color:"rgba(218, 218, 218, 1)", fontSize:"19px"}}>Home</Link></button>
                                <button><a className="navLink" href="/#services" style={{color:"rgba(218, 218, 218, 1)", fontSize:"19px"}} onMouseOut={removeSmoothScroll} onMouseOver={addSmoothScroll}>Services</a></button>
                                <button><a className="navLink" href="/#work-hyperLink" style={{color:"rgba(218, 218, 218, 1)", fontSize:"19px"}} onMouseOut={removeSmoothScroll} onMouseOver={addSmoothScroll}>Work</a></button>
                                <button><a href="/#aboutUs" className="navLink" style={{color:"rgba(218, 218, 218, 1)", fontSize:"19px"}} onMouseOut={removeSmoothScroll} onMouseOver={addSmoothScroll}>About Us</a></button>
                                <button><Link className="navLink" to="/career" style={{color:"rgba(218, 218, 218, 1)", fontSize:"19px"}}>Career</Link></button>
                                <button><a href="/#blogs" className="navLink" style={{color:"rgba(218, 218, 218, 1)", fontSize:"19px"}} onMouseOut={removeSmoothScroll} onMouseOver={addSmoothScroll}>Blogs</a></button>
                            </div>
                        </div>
                        <div className="footer-nav-logo">
                            <img src="/images/footer_circle_logo.svg" alt="" />
                            <img src="/images/review_tag.svg" alt="" />
                        </div>
                    </div>

                    <div className="footer-adress-container">
                        <div className="local_adress_container">
                            <ul>
                                <li className="cityname">Bengaluru</li>
                                <p className="bengaluru_adress">
                                    #19, 3rd Main, 1st Cross, Hosapalya, Bommanahalli Bengaluru, 560068
                                </p>
                            </ul>

                            <ul>
                                <li className="cityname">Dubai</li>
                                <p className="dubai_adress">
                                    One Central, Office 304/305, The Offices 2, Level 3 - Dubai - United Arab Emirates
                                </p>
                            </ul>
                        </div>

                        <p className="footer-country-tag">
                            Designed in Benguluru, India <img src="/images/indian_flag.svg" alt="" />
                        </p>

                        <div className="footer-nav-logo-mobile">
                            <img src="/images/footer_circle_logo.svg" alt="" />
                            <img src="/images/review_tag.svg" alt="" />
                        </div>
                    </div>


                    <div className="footer-social-nav-container">
                        <div className="social-link-bar-mobile">
                            <a>
                                <img src="/images/world_icon.svg" alt="" />
                            </a>
                            <a  href="https://www.behance.net/dzgn-macha" target="_blank">
                                <img src="/images/behance_icon.svg" alt="" />
                            </a>
                            <a href="https://www.instagram.com/dzgn.macha?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">
                                <img src="/images/insta_icon.svg" alt="" />
                            </a>
                            <a href=" https://www.linkedin.com/company/dzgnmacha/" target="_blank">
                                <img src="/images/linkedin_icon.svg" alt="" />
                            </a>
                        </div>

                        <div className="footer-img-link-mobile">
                            <a href="#">Terms & Conditions</a>
                            <a href="#">Privacy Policy</a>
                        </div>

                        <div className="footer-copyright-container">
                            <p className="copyright-para">©DesignMacha 2023 - 2024</p>
                            <p className="media-asset"><img src="/images/redDot.svg" alt="" /> &nbsp;  &nbsp;Media Assets</p>
                        </div>

                        <div className="social-link-bar">
                            <a>
                                <img src="/images/world_icon.svg" alt="" />
                            </a>
                            <a href="https://www.behance.net/dzgn-macha" target="_blank">
                                <img src="/images/behance_icon.svg" alt="" />
                            </a>
                            <a href="https://www.instagram.com/dzgn.macha?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">
                                <img src="/images/insta_icon.svg" alt="" />
                            </a>
                            <a href=" https://www.linkedin.com/company/dzgnmacha/" target="_blank">
                                <img src="/images/linkedin_icon.svg" alt="" />
                            </a>
                        </div>

                        <div className="footer-img-link">
                            <a href="#">Terms & Conditions</a>
                            <a href="#">Privacy Policy</a>
                        </div>
                    </div>
                </div>
             </footer>
            </BrowserRouter>
        </div>
    )
}